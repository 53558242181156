import { TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CardProcedimentos from '../../components/card_procedimentos/index';
import React, { useEffect, useState, useRef } from 'react';
import { ValorCustom } from '../../services/mascaras';
import Navbar from '../../components/navbar/index';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import api from '../../services/api';
import PropTypes from "prop-types";
import { parse } from 'date-fns';
import './procedimentos.css';

//Definição das props das mascaras
ValorCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

//Pagina que lista todos os cards dos procedimentos
export default function Procedimentos() {
    const [disableFiltro, setDisableFiltro] = useState(true);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [tipo] = useState(() => {
        if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
            return true
        else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
            return false
        else {
            api
                .post('/logout')
                .then(() => {
                    sessionStorage.clear();
                    history.replace('/');
                })
                .catch((err) => console.log(err));
        }
    });

    //Arrays de procedimentos
    const [procedimentos, setProcedimentos] = useState([]);
    const [medico, setMedico] = useState(false);
    const [filteredProcedimentos, setFilteredProcedimentos] = useState(null);

    //Consts do filtro
    const [filtro, setFiltro] = useState('');
    const filtroRef = useRef(null);
    const [opcoesFiltro, setOpcoesFiltro] = useState('');
    const opcoesFiltroRef = useRef(null);
    const [DataInicial, handleDataInicial] = useState(null);
    const dataRef = useRef(null);

    useEffect(() => {
        let rota = tipo ? '/procedimento/medico/' : '/procedimento/assistente/';

        api
            .get(rota + JSON.parse(sessionStorage.getItem('data')).uid)
            .then((res) => {
                let temp = [];
                if (tipo) {
                    if (res.data === 'Não existe procedimento com o registro desse médico') {
                        setDisableFiltro(false);
                        setProcedimentos([]);
                        setLoading(false);
                        return;
                    }
                    res.data.forEach((pcd) => {
                        pcd.medico_id = JSON.parse(sessionStorage.getItem('data')).uid;
                        pcd.medico_email = JSON.parse(sessionStorage.getItem('data')).email;
                        pcd.medico_name = JSON.parse(sessionStorage.getItem('data')).name;
                        temp.push(pcd);
                    });
                }
                else {
                    res.data.forEach((data) => {
                        setMedico(true);
                        data.procedimentos_list.forEach((pcd) => {
                            pcd.medico_id = data.medico_id;
                            pcd.medico_email = data.medico_email;
                            pcd.medico_name = data.medico_name;
                            temp.push(pcd);
                        });
                    });
                }
                temp.sort((a, b) => {
                    a = parse(
                        a.date,
                        "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
                        new Date(),
                    );
                    b = parse(
                        b.date,
                        "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
                        new Date(),
                    );
                    if (a > b) {
                        return -1;
                    } else if (a < b) {
                        return 1;
                    }
                    return 0;
                });

                setDisableFiltro(false);
                setProcedimentos(temp);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data === 'Não existe procedimento com o registro desse médico') {
                            setDisableFiltro(false);
                            setProcedimentos([]);
                            setLoading(false);
                            return;
                        }
                        else if (err.response.data === "Unauthorized") {
                            api
                                .post('/logout')
                                .then(() => {
                                    sessionStorage.clear();
                                    window.location.replace('/');
                                })
                                .catch((err) => console.log(err));
                        }
                        else
                            alert(err.response.data);
                    }
                    else
                        alert(err.response);
                }
                else
                    alert(err);
            })
    }, [tipo]);

    const handleFiltro = (opcoes, inputf) => {
        setOpcoesFiltro(opcoes);
        if (inputf instanceof Date) {
            handleDataInicial(inputf);
            if (opcoes === "dataMes")
                inputf = (inputf.getMonth() + 1) + '/' + inputf.getFullYear();
            else if (opcoes === "dataDia")
                inputf = (inputf.getDate() + 1) + '/' + (inputf.getMonth() + 1) + '/' + inputf.getFullYear();
        }
        else
            setFiltro(inputf);

        //Vê se alguma opção foi selecionada no select
        if (opcoes && opcoes !== '' && inputf && inputf !== '')
            setFilteredProcedimentos(procedimentos.filter((pcd) => {
                let data = new Date(pcd.date);
                switch (opcoes) {
                    case "dataMes":
                        data = (data.getMonth() + 1) + '/' + data.getFullYear();
                        if (data === inputf)
                            return pcd;
                        break;
                    case "dataDia":
                        data = (data.getDate() + 1) + '/' + (data.getMonth() + 1) + '/' + data.getFullYear();
                        if (data === inputf)
                            return pcd;
                        break;
                    case "registered_value":
                        if (!Array.isArray(pcd.registered_value)) {
                            if (pcd.registered_value === parseFloat(inputf))
                                return pcd;
                        } else {
                            let controller = false;
                            pcd.registered_value.forEach((valor) => {
                                if (valor === parseFloat(inputf))
                                    controller = true;
                            });
                            if (controller)
                                return pcd;
                        }
                        break;
                    case "tuss":
                        if (!Array.isArray(pcd.tuss)) {
                            if (pcd.tuss.code.toString().toLowerCase().indexOf(inputf.toLowerCase()) > -1)
                                return pcd;
                        } else {
                            let controller = false;
                            pcd.tuss.forEach((tussAtual) => {
                                if (tussAtual.code.toString().toLowerCase().indexOf(inputf.toLowerCase()) > -1)
                                    controller = true;
                            });
                            if (controller)
                                return pcd;
                        }
                        break;
                    default:
                        if (pcd[opcoes].toLowerCase().indexOf(inputf.toLowerCase()) > -1)
                            return pcd;
                        break;
                }
                return false;
            }));
    }

    const removeCard = (deletado) => {
        let temp = procedimentos.filter(({ id }) => id !== deletado);
        setProcedimentos(temp);
    };

    return (
        <div className="procedimentosContainer">

            <Navbar />

            {/* Filtro dos procedimentos */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <section className="filtroProcedimentos">
                    <FormControl variant="outlined" margin="dense" className="procedimentosSelect">
                        <InputLabel id="filtro-label">Filtrar por:</InputLabel>
                        <Select
                            required
                            labelId="filtro-label"
                            id="tipoFiltro"
                            className="procedimentosSelectInput"
                            value={opcoesFiltro}
                            inputRef={opcoesFiltroRef}
                            label="Filtrar por:"
                            name="select"
                            margin="dense"
                            disabled={disableFiltro}
                            onChange={(e) => handleFiltro(e.target.value, filtro)}
                        >
                            {!tipo && <MenuItem value={"medico_name"}>Médico</MenuItem>}
                            <MenuItem value={"clinic_name"}>Clínica</MenuItem>
                            <MenuItem value={"dataDia"}>Dia</MenuItem>
                            <MenuItem value={"dataMes"}>Mês</MenuItem>
                            <MenuItem value={"patient_name"}>Paciente</MenuItem>
                            <MenuItem value={"health_plan"}>Plano</MenuItem>
                            <MenuItem value={"tuss"}>TUSS</MenuItem>
                            <MenuItem value={"registered_value"}>Valor</MenuItem>
                        </Select>
                    </FormControl>

                    {opcoesFiltro !== "dataMes" && opcoesFiltro !== "dataDia" &&
                        <TextField
                            id="filtro"
                            margin="dense"
                            label="contem:"
                            variant="outlined"
                            className="procedimentosInput"
                            disabled={disableFiltro}
                            value={filtro}
                            inputRef={filtroRef}
                            onChange={(e) => handleFiltro(opcoesFiltro, e.target.value)}
                            type="text"
                            name="filtro"
                            InputProps={opcoesFiltro === "valor" ? { inputComponent: ValorCustom } : undefined}
                        />
                    }
                    {(opcoesFiltro === "dataMes" || opcoesFiltro === "dataDia") &&
                        <DatePicker
                            id="data-inicial"
                            inputRef={dataRef}
                            openTo={opcoesFiltro === "dataMes" ? "month" : "date"}
                            disabled={disableFiltro}
                            views={opcoesFiltro === "dataMes" ? ["year", "month"] : ["year", "month", "date"]}
                            variant="inline"
                            inputVariant="outlined"
                            format={opcoesFiltro === "dataMes" ? "MM/yyyy" : "dd/MM/yyyy"}
                            className="procedimentosInput"
                            value={DataInicial}
                            label={opcoesFiltro === "dataMes" ? "Selecione um Mês" : "Selecione uma data"}
                            margin="dense"
                            onChange={(e) => handleFiltro(opcoesFiltro, e)}
                        />
                    }
                    <div className="divFiltroProcedimentos">
                        <FormControl variant="outlined" margin="dense" className="procedimentosSelectResponsivo">
                            <InputLabel id="filtro-label">Filtrar por:</InputLabel>
                            <Select
                                required
                                labelId="filtro-label"
                                id="tipoFiltro"
                                value={opcoesFiltro}
                                inputRef={opcoesFiltroRef}
                                label="Filtrar por:"
                                name="select"
                                margin="dense"
                                className="procedimentosSelectInput"
                                disabled={disableFiltro}
                                onChange={(e) => handleFiltro(e.target.value, filtro)}
                            >
                                <MenuItem value={"clinic_name"}>Clínica</MenuItem>
                                <MenuItem value={"dataDia"}>Dia</MenuItem>
                                <MenuItem value={"dataMes"}>Mês</MenuItem>
                                <MenuItem value={"patient_name"}>Paciente</MenuItem>
                                <MenuItem value={"health_plan"}>Plano</MenuItem>
                                <MenuItem value={"tuss"}>TUSS</MenuItem>
                                <MenuItem value={"registered_value"}>Valor</MenuItem>
                            </Select>
                        </FormControl>
                        <button
                            className="procedimentosBtnFiltro"
                            onClick={() => {
                                setOpcoesFiltro('')
                                setFiltro('')
                                setFilteredProcedimentos(null)
                            }}
                        >
                            Limpar Filtro
                        </button>
                    </div>
                </section>
            </MuiPickersUtilsProvider>

            <section className="cardsProcedSection">
                {loading &&
                    <CircularProgress className="procedLoader" thickness={5} />
                }
                {!loading && !filteredProcedimentos &&
                    procedimentos.map((pcd, index) => {
                        return (
                            <CardProcedimentos
                                key={index}
                                id={pcd.id}
                                idMedico={pcd.medico_id}
                                nomeMedico={pcd.medico_name}
                                emailMedico={pcd.medico_email}
                                idClinica={pcd.clinic_id}
                                clinica={pcd.clinic_name}
                                endereco={pcd.clinic_address}
                                data={pcd.date}
                                paciente={pcd.patient_name}
                                tuss={pcd.tuss}
                                plano={pcd.health_plan}
                                valores={pcd.registered_value}
                                valorPago={pcd.received_value}
                                removedor={removeCard}
                            />
                        )
                    })
                }
                {!loading && procedimentos.length === 0 && !medico && !tipo &&
                    <p className="procedimentosArrayVazio">Você não está vinculado a nenhum médico</p>
                }
                {!loading && procedimentos.length === 0 && medico && !tipo &&
                    <p className="procedimentosArrayVazio">Não existem atendimentos cadastrados</p>
                }
                {!loading && procedimentos.length === 0 && tipo &&
                    <p className="procedimentosArrayVazio">Não existem atendimentos cadastrados</p>
                }
                {filteredProcedimentos &&
                    filteredProcedimentos.map((pcd, index) => {
                        return (
                            <CardProcedimentos
                                key={index}
                                id={pcd.id}
                                idMedico={pcd.medico_id}
                                nomeMedico={pcd.medico_name}
                                emailMedico={pcd.medico_email}
                                idClinica={pcd.clinic_id}
                                clinica={pcd.clinic_name}
                                endereco={pcd.clinic_address}
                                data={pcd.date}
                                paciente={pcd.patient_name}
                                tuss={pcd.tuss}
                                plano={pcd.health_plan}
                                valores={pcd.registered_value}
                                valorPago={pcd.received_value}
                                removedor={removeCard}
                            />
                        )
                    })
                }
            </section>
        </div>
    );
}