import NumberFormat from 'react-number-format';

//Mascara do input do Telefone
export const TelefoneCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            format="(##) #####-####" 
            mask="_"
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            isNumericString
        />
    );
}

//Macara do input do CPF
export const CPFCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            format="###.###.###-##" 
            mask="_"
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            isNumericString
        />
    );
}
//Macara do input de valor do filtro
export const ValorCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            decimalScale="2"
            prefix=" R$ "
            decimalSeparator=","
            fixedDecimalScale
            thousandSeparator="."
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            isNumericString
        />
    );
}