//Formata o número de telefone com parentese no dd e traço no meio
export const formataTelefone = (tel) => {
    if (tel.length === 10)
        tel = "(" + tel.substring(0, 2) + ") " + tel.substring(2, 6) + "-" + tel.substring(6, 10);
    else
        tel = "(" + tel.substring(0, 2) + ") " + tel.substring(2, 7) + "-" + tel.substring(7, 11);

    return tel
}

//Verifica maioridade
export const verificaIdade = (aniversario) => {
    let today = new Date();
    let age = today.getFullYear() - aniversario.getFullYear();
    let m = today.getMonth() - aniversario.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < aniversario.getDate())) {
        age--;
    }
    return age
}