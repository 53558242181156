import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Logo from "./../../assets/LOGO BRANCA sem TITULO.png";
import { useLocation, useHistory } from "react-router-dom";
import { TextField, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import firebase from "firebase/app";
import CryptoJS from "crypto-js";
import "firebase/auth";
import "./handler.css";

//Input Styles
const useStyles = makeStyles({
    root: {
        width: '75%',
        color: 'white',
        marginBottom: '3%',
        '& fieldset': {
            borderColor: 'white',
            color: 'white',
            borderWidth: 1,
        },
        '& input': {
            color: 'white',
        },
        '& label': {
            color: 'white',
        },
        '& input:valid:focus + fieldset': {
            borderColor: 'white',
            color: 'white',
        },
        '& input:invalid:focus + fieldset': {
            borderColor: 'white',
            color: 'white',
        },
        '& input:valid:hover + fieldset': {
            borderColor: 'white',
            color: 'white',
        },
        '& input:invalid:hover + fieldset': {
            borderColor: 'white',
            color: 'white',
        },
    },
});

//Email handler
export default function EmailHandler() {
    const location = useLocation().search;
    const params = useMemo(() => new URLSearchParams(location), [location]);
    const history = useHistory();
    const classes = useStyles();

    //URL parameters
    let mode = params.get('mode');
    let actionCode = params.get('oobCode');

    //User Info
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const senhaRef = useRef(null);
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const confirmaSenhaRef = useRef(null);
    const [resetado, setResetado] = useState(false);

    //Render controllers
    const [invalid, setInvalid] = useState(false);
    const [render, setRender] = useState('');
    const [redefinirEmail, setRedefinirEmail] = useState(false);
    const [trocaSenha, setTrocaSenha] = useState(false);

    //Alert variables
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        switch (mode) {
            case 'resetPassword':
                firebase.auth()
                    .verifyPasswordResetCode(actionCode)
                    .then((email) => {
                        setEmail(email);
                        setRender('resetPassword');
                    })
                    .catch((error) => {
                        setInvalid(true);
                        console.log(error);
                    });
                break;
            case 'recoverEmail':
                firebase.auth()
                    .checkActionCode(actionCode)
                    .then((info) => {
                        setEmail(info.data.email);
                        setRender('recoverEmail');
                    })
                    .catch((error) => {
                        setInvalid(true);
                        console.log(error);
                    });
                break;
            case 'verifyEmail':
                firebase.auth()
                    .applyActionCode(actionCode)
                    .then((resp) => setRender('verifyEmail'))
                    .catch((error) => {
                        setInvalid(true);
                        console.log(error);
                    });
                break;
            default:
                history.replace('/');
        };
    }, [actionCode, mode, history]);


    const handlePassReset = () => {
        if (senha !== confirmaSenha) {
            setMessage('Senhas digitadas são diferentes');
            setOpen(true);
            setConfirmaSenha('');
            confirmaSenhaRef.current.focus();
            return;
        }
        else if (senha.length < 8 || senha.length > 20) {
            console.log(senha)
            setMessage("A senha digitada tem que ter no minimo 8 e no máximo 20 caracteres");
            setSenha('');
            setConfirmaSenha('');
            setOpen(true);
            senhaRef.current.focus();
            return;
        }

        let pass = CryptoJS.SHA1(senha);
        firebase.auth()
            .confirmPasswordReset(actionCode, pass.toString())
            .then((resp) => setResetado(true))
            .catch((error) => console.log(error.response.data.error.message));
    };

    const handlePassResetEmail = () => {
        if (senha !== confirmaSenha) {
            setMessage('Senhas digitadas são diferentes');
            setOpen(true);
            setConfirmaSenha('');
            confirmaSenhaRef.current.focus();
            return;
        }
        else if (senha.length < 8 || senha.length > 20) {
            setMessage("A senha digitada tem que ter no minimo 8 e no máximo 20 caracteres");
            setSenha('');
            setConfirmaSenha('');
            setOpen(true);
            senhaRef.current.focus();
            return;
        }

        let pass = CryptoJS.SHA1(senha);
        firebase.auth()
            .confirmPasswordReset(actionCode, pass.toString())
            .then((resp) => {
                setResetado(true);
                setTrocaSenha(false);
            })
            .catch((error) => alert(error));
    };

    const recuperaEmail = () => {
        firebase.auth()
            .applyActionCode(actionCode)
            .then(() => setRedefinirEmail(true))
            .catch((error) => alert(error));
    };

    return (
        <>
            <Collapse in={open} className="Alerta">
                <Alert
                    variant="filled"
                    severity="error"
                    onClose={() => { setOpen(false) }}
                >
                    {message}
                </Alert>
            </Collapse>
            <div className="handlerCard">
                {invalid &&
                    <>
                        <p className="handlerExpired">Link invalido ou expirado!</p>
                    </>
                }
                {!invalid && render === 'resetPassword' && !resetado &&
                    <>
                        <p className="handlerTitle">Redefinir senha</p>
                        <p className="handlerText">Redefinir senha para a conta {email}</p>
                        <TextField
                            id="senha"
                            value={senha}
                            inputRef={senhaRef}
                            onChange={(n) => setSenha(n.target.value)}
                            label="Nova senha"
                            autoComplete="new-password"
                            name="senha"
                            type="password"
                            variant="outlined"
                            required
                            className="handlerInput"
                            classes={{
                                root: classes.root,
                            }}
                        />
                        <TextField
                            id="conf-senha"
                            value={confirmaSenha}
                            inputRef={confirmaSenhaRef}
                            onChange={(n) => setConfirmaSenha(n.target.value)}
                            label="Repita a nova senha"
                            autoComplete="new-password"
                            name="senha"
                            type="password"
                            variant="outlined"
                            required
                            className="handlerInput"
                            classes={{
                                root: classes.root,
                            }}
                        />
                        <button
                            className="handlerBtn"
                            onClick={handlePassReset}
                        >
                            Alterar
                        </button>

                    </>
                }
                {!invalid && resetado &&
                    <>
                        <p
                            className="handlerTitle"
                            style={{ fontSize: '150%', marginTop: '35%', marginBottom: '5%' }}
                        >Senha resetada com sucesso!</p>
                        <div className="handlerAcessar" onClick={() => history.push('/escolhe_usuario')} >
                            <p style={{ margin: 0 }}>Acessar a Plataforma</p>
                            <ArrowForwardIosIcon className="handlerAcessarIcon" />
                        </div>
                    </>
                }
                {!invalid && render === 'recoverEmail' &&
                    <>
                        {!resetado && <p className="handlerTitle">Recuperar email</p>}
                        {!redefinirEmail && !trocaSenha && !trocaSenha &&
                            <>
                                <p className="handlerText" style={{ marginTop: '7%' }}>Se você não alterou seu email para {email} clique abaixo para restaurá-lo</p>
                                <button
                                    className="handlerBtnEmail"
                                    onClick={recuperaEmail}
                                >
                                    Restaurar meu email
                                </button>
                            </>
                        }
                        {redefinirEmail && !trocaSenha && !resetado &&
                            <>
                                <p className="handlerText" style={{ marginTop: '2%', fontWeight: '700' }}>Email Redefinido com sucesso!</p>
                                <p className="handlerText" style={{ marginTop: '-5%' }}>Se você acha que sua conta foi comprometida recomendamos que você troque sua senha</p>
                                <button
                                    className="handlerBtnEmail"
                                    onClick={() => setTrocaSenha(true)}
                                >
                                    Redefinir minha senha!
                                </button>
                            </>
                        }
                        {redefinirEmail && trocaSenha &&
                            <>
                                <p className="handlerText" style={{ marginTop: '2%' }}>Digite sua nova senha</p>
                                <TextField
                                    id="senha"
                                    value={senha}
                                    inputRef={senhaRef}
                                    onChange={(n) => setSenha(n.target.value)}
                                    label="Nova senha"
                                    autoComplete="new-password"
                                    name="senha"
                                    type="password"
                                    variant="outlined"
                                    required
                                    className="handlerInput"
                                    classes={{
                                        root: classes.root,
                                    }}
                                />
                                <TextField
                                    id="senha"
                                    value={confirmaSenha}
                                    inputRef={confirmaSenhaRef}
                                    onChange={(n) => setConfirmaSenha(n.target.value)}
                                    label="Repita a nova senha"
                                    autoComplete="new-password"
                                    name="senha"
                                    type="password"
                                    variant="outlined"
                                    required
                                    className="handlerInput"
                                    classes={{
                                        root: classes.root,
                                    }}
                                />
                                <button
                                    className="handlerBtn"
                                    onClick={handlePassResetEmail}
                                >
                                    Alterar
                                </button>
                            </>
                        }
                    </>
                }
                {!invalid && render === 'verifyEmail' &&
                    <>
                        <p
                            className="handlerTitle"
                            style={{ fontSize: '150%', marginTop: '35%', marginBottom: '5%' }}
                        >Email verificado com sucesso!</p>
                        <div className="handlerAcessar" onClick={() => history.push('/escolhe_usuario')} >
                            <p style={{ margin: 0 }}>Acessar a Plataforma</p>
                            <ArrowForwardIosIcon className="handlerAcessarIcon" />
                        </div>
                    </>
                }
                <img className="handlerLogo" src={Logo} alt="Logo CashCare" />
            </div>
        </>
    )
}
