import { DialogContentText, DialogActions, DialogContent, DialogTitle, Button, Dialog, CircularProgress, Tooltip } from '@material-ui/core';
import React, { useState, memo, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import api from '../../services/api';
import './card_clinica.css';

function CardClinica({ id, nome, nomeMedico, endereco, telefone, email, customClickEvent, removedor }) {
  const [deletando, setDeletando] = useState(false);
  const [open, setOpen] = useState(false);

  //Apaga clinica do banco
  const handleAceitar = useCallback(() => {
    setDeletando(true);
    api
      .delete('/clinica/' + id)
      .then(() => {
        setOpen(false);
        setDeletando(false);
        removedor(id);
      })
      .catch((err) => {
        setOpen(false);
        setDeletando(false);
        if (err.response) {
          if (err.response.data) {
            if (err.response.data === "Unauthorized") {
              api
                .post('/logout')
                .then(() => {
                  sessionStorage.clear();
                  window.location.replace('/');
                })
                .catch((err) => console.log(err));
            }
            else
              alert(err.response.data);
          }
          else
            alert(err.response);
        }
        else
          alert(err);
      })
  }, [removedor, id]);

  return (

    <div className="cardClinica" >
      <div className='divIconsClinicaCard'>
        <Tooltip title="Editar Clinica">
          <EditIcon onClick={customClickEvent} className='editarClinica' style={{ fontSize: 25, color: 'white' }} />
        </Tooltip>
        <Tooltip title="Apagar Clinica">
          <DeleteIcon onClick={() => setOpen(true)} className='apagarClinica' style={{ fontSize: 25, color: 'white' }} />
        </Tooltip>
      </div>

      <div className="cardClinicaInfo">
        <p>{nome}</p>
        {nomeMedico &&
          <div>
            <p>Médico: </p>
            <p>{nomeMedico}</p>
          </div>
        }
        <div>
          <p>Endereço: </p>
          <p>{endereco}</p>
        </div>
        {telefone &&
          <div>
            <p>Telefone: </p>
            <p>{telefone}</p>
          </div>
        }
        {email &&
          <div>
            <p>Email: </p>
            <p>{email}</p>
          </div>
        }
      </div>

      {/* modal confirmação da remoção da clinica */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirmar de exclusão:"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja deletar essa clínica?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!deletando &&
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
          }
          <Button onClick={handleAceitar} disabled={deletando} color="primary" style={deletando ? { width: 150 } : {}}>
            {deletando && <CircularProgress className="loadingCardEquipe" />}
            {!deletando && 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}

export default memo(CardClinica);