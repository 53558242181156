import Navbar2 from '../../components/navbar2/index';
import Footer from '../../components/footer/index';
import React, { useEffect } from 'react';
import './planos.css';

//Tela com planos e preços da aplicação
export default function Planos() {

  //Sobe a pagina depois que carrega
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="planosContainer">
      <Navbar2 />

      <div>
        <h1 className="planostitulo">Conheça nossos planos</h1>
      </div>

      <div className="divider" />

      {/* Plano 1 */}
      <div className="planoCard">
        <div>
          <h1 className="planoTitulo">PLANO 01</h1>
        </div>

        <div className="planoInfo">
          <div className="textoPlanosDiv">
            <p className="textoPlanos">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          </div>
          <div className="valorPlanosDiv">
            <p className="valorPlanos">Preço: R$00.00</p>
          </div>
        </div>
      </div>

      {/* Plano 2 */}
      <div className="planoCard">
        <div>
          <h1 className="planoTitulo">PLANO 02</h1>
        </div>

        <div className="planoInfo">
          <div className="textoPlanosDiv">
            <p className="textoPlanos">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          </div>
          <div className="valorPlanosDiv">
            <p className="valorPlanos">Preço: R$00.00</p>
          </div>
        </div>
      </div>

      {/* Plano 3 */}
      <div className="planoCard">
        <div>
          <h1 className="planoTitulo">PLANO 03</h1>
        </div>

        <div className="planoInfo">
          <div className="textoPlanosDiv">
            <p className="textoPlanos">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          </div>
          <div className="valorPlanosDiv">
            <p className="valorPlanos">Preço: R$00.00</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}