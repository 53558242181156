import DateFnsUtils from '@date-io/date-fns';
import { Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import FacebookIcon from '@material-ui/icons/Facebook';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from 'axios';
import CryptoJS from "crypto-js";
import 'date-fns';
import { format } from 'date-fns';
import firebase from "firebase/app";
import "firebase/auth";
import { validate } from 'gerador-validador-cpf';
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import api from '../../services/api';
import { CPFCustom, TelefoneCustom } from '../../services/mascaras';
import { verificaIdade } from '../../services/utils';
import './login.css';

//Definição das props da mascara
TelefoneCustom.propTypes = CPFCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

//Parte do Autocomplete
const filter = createFilterOptions();

//Animação do modal
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//Tela de login da plataforma
function Login() {
    //type vê se o usuário escolheu médico ou assistente
    let location = useLocation().search;
    let type = useMemo(() => new URLSearchParams(location), [location]);
    const [enviando, setEnviando] = useState(false);
    const [enviandocad, setEnviandocad] = useState(false);
    const [social, setSocial] = useState(false);
    const history = useHistory();

    //User info
    const [email, setEmail] = useState('');
    const emailRef = useRef(null);
    const [senha, setSenha] = useState('');
    const senhaRef = useRef(null);
    const [nome, setNome] = useState('');
    const nomeRef = useRef(null);
    const [tel, setTel] = useState('');
    const telRef = useRef(null);
    const [esp, setEsp] = useState('');
    const espRef = useRef(null);
    const [cpf, setCPF] = useState('');
    const cpfRef = useRef(null);
    const [niver, setNiver] = useState(null);
    const niverRef = useRef(null);
    const [crm, setCRM] = useState('');
    const crmRef = useRef(null);
    const [UF, setUF] = useState('');
    const UFRef = useRef(null);
    const [emailcad, setEmailcad] = useState('');
    const emailcadRef = useRef(null);

    //Consts do Alerta
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    //Consts do Modal de recuperaçãp de senha
    const [openSenha, setOpenSenha] = useState(false);
    const [emailSenha, setEmailSenha] = useState("");

    //Consts do Modal de verificação de email
    const [openEmail, setOpenEmail] = useState(false);

    //Consts do Modal de finalização de cadastro
    const [openCad, setOpenCad] = useState(false);
    const [userId, setUserId] = useState('');
    const [token, setToken] = useState('');
    const [openAlertForm, setOpenAlertForm] = useState(false);
    const [messageForm, setMessageForm] = useState('');
    //Array de especialidades
    const [especialidade, setEspecialidade] = useState([]);

    //Pega as informações necessárias do google e abre o modal de finialização do cadastro
    const finalizaCadastro = useCallback(async (dados) => {
        setSocial(false);
        let arr = [];

        //Se o usuário for médico pega as especialidades pro autocomplete
        if (type.get("tipo") === 'medico') {
            const getEsp = await api.get('/specialty').then(res => ({ data: res.data, promise: 'especialidades' }));
            arr.push(getEsp);
        }

        if ((dados.providerId === 'google.com') && (dados.scopes.indexOf('https://www.googleapis.com/auth/user.birthday.read') !== -1 || dados.scopes.indexOf('https://www.googleapis.com/auth/user.phonenumbers.read') !== -1)) {
            const getUser = await axios
                .get('https://content-people.googleapis.com/v1/people/me?personFields=birthdays,phoneNumbers', {
                    headers: {
                        authorization: `Bearer ${dados.token}`
                    }
                })
                .then(res => ({ data: res.data, promise: 'google' }));
            arr.push(getUser);
        }

        await Promise
            .all(arr)
            .then((res) => {
                if (res.length > 0) {
                    if (res[0].promise === 'especialidades') {
                        let espUnicas = [...new Set(res[0].data)];
                        espUnicas = espUnicas.map((esp) => {
                            return {
                                title: esp,
                                value: esp
                            }
                        })
                        setEspecialidade(espUnicas);
                    }


                    if (dados.providerId === 'google.com') {
                        let resGoogle = res[0].promise === 'google' ? res[0].data : res[1].data;

                        if (resGoogle.birthdays) {
                            let data = resGoogle.birthdays[0].date;
                            setNiver(new Date(`${data.year}-${data.month}-${data.day}`));
                        };

                        if (resGoogle.phoneNumbers) {
                            let phone = resGoogle.phoneNumbers[0].value.replace('-', '').replace('(', '').replace(')', '')
                            setTel(phone);
                        };
                        setNome(dados.name);
                        setEmailcad(dados.email);
                    }
                }
                if (dados.providerId === 'facebook.com') {
                    if (dados.scopes.indexOf("email") !== -1 || dados.scopes.indexOf("public_profile") !== -1) {
                        setNome(dados.name);
                        setNiver(new Date(dados.birthday));
                        setEmailcad(dados.email);
                    };
                };
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data) {
                        alert(err.response.data);
                    }
                    else
                        alert(err.response);
                }
                else
                    alert(err);
            });
    }, [type]);

    //Faz a request do login
    const postLogin = useCallback((idToken, uid, provider) => {
        api
            .post('/login', {
                idToken: idToken,
                uid: uid,
                type: type.get("tipo")
                // csrfToken: csrfToken,
            })
            .then(({ data }) => {
                let json = {
                    userTipo: type.get("tipo"),
                    name: data.name,
                    phone: data.phone,
                    birth: data.birth,
                    uid: data.uid,
                    cpf: data.cpf,
                    email: data.email,
                };

                if (type.get("tipo") === 'medico') {
                    json.crm = data.crm;
                    json.uf = data.uf;
                    json.specialty = data.specialty;

                    sessionStorage.setItem('data', JSON.stringify(json));
                    setEnviando(false);
                    setSocial(false);
                    if (sessionStorage.getItem('reautenticacao') === 'true') {
                        sessionStorage.removeItem('reautenticacao')
                        history.push('/editar_perfil');
                    }
                    else
                        history.push('/grafico');
                }
                else {
                    sessionStorage.setItem('data', JSON.stringify(json));
                    setEnviando(false);
                    setSocial(false);
                    if (sessionStorage.getItem('reautenticacao') === 'true') {
                        sessionStorage.removeItem('reautenticacao')
                        history.push('/editar_perfil');
                    }
                    else
                        history.push('/atendimentos');
                }
            })
            .catch((err) => {
                setEnviando(false);
                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data === "No matching documents.") {
                            if (provider.providerId === 'google.com' || provider.providerId === 'facebook.com' || provider.providerId === 'apple.com') {
                                setOpenCad(true);
                                finalizaCadastro(provider);
                            }
                            else {
                                let temp = type.get("tipo") === 'medico' ? 'médico' : 'assistente';
                                setMessage("Usuário não é um " + temp);
                                setOpen(true);
                                setEmail('');
                                setSenha('');
                            }
                        }
                        else {
                            setSocial(false);
                            alert(err.response.data);
                        }
                    }
                    else {
                        setSocial(false);
                        alert(err.response);
                    }
                }
                else {
                    setSocial(false);
                    alert(err);
                }
            });
    }, [finalizaCadastro, history, type]);

    //Verifica se é redirecionamento do login com redes sociais
    useEffect(() => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        firebase.auth()
            .getRedirectResult()
            .then((result) => {
                if (sessionStorage.getItem('redirect')) {
                    sessionStorage.removeItem('redirect');
                    setSocial(true);
                }
                if (result.credential) {
                    result.user
                        .getIdToken(true)
                        .then((idToken) => {
                            setUserId(result.user.uid);
                            setToken(idToken);

                            let dados = result.additionalUserInfo.profile;
                            if (result.additionalUserInfo.isNewUser) {
                                setOpenCad(true);
                                finalizaCadastro({
                                    token: result.credential.accessToken,
                                    providerId: result.additionalUserInfo.providerId,
                                    name: dados.name,
                                    email: dados.email,
                                    birthday: dados.birthday,
                                    scopes: dados.granted_scopes
                                });
                            } else {
                                if (result.additionalUserInfo.providerId === 'apple.com')
                                    postLogin(idToken, result.user.uid, {
                                        token: result.credential.accessToken,
                                        name: dados.name,
                                        providerId: result.additionalUserInfo.providerId,
                                        email: dados.email,
                                        scopes: dados.granted_scopes
                                    });
                                if (result.additionalUserInfo.providerId === 'google.com')
                                    postLogin(idToken, result.user.uid, {
                                        token: result.credential.accessToken,
                                        name: dados.name,
                                        providerId: result.additionalUserInfo.providerId,
                                        email: dados.email,
                                        scopes: dados.granted_scopes
                                    });
                                else if (result.additionalUserInfo.providerId === 'facebook.com') {
                                    postLogin(idToken, result.user.uid, {
                                        token: result.credential.accessToken,
                                        providerId: result.additionalUserInfo.providerId,
                                        name: dados.name,
                                        email: dados.email,
                                        birthday: dados.birthday,
                                        scopes: dados.granted_scopes
                                    });
                                }
                            }
                        })
                        .catch((err) => {
                            alert(`ERRO! Código: ${err.code}. \n ${err.message}`);
                        });
                } else {
                    setSocial(false);
                }
            })
            .catch((error) => {
                setSocial(false);
                if (error.code === 'auth/account-exists-with-different-credential') {
                    setMessage("Usuário já cadastrado com " + error.providerId === 'google.com' ? 'Google!' : error.providerId === 'facebook.com' ? 'Facebook!' : 'AppleId!');
                    setOpen(true);
                }
                else
                    alert('Erro! Código: ' + error.code + '\n' + error.message);
            });
    }, [postLogin, finalizaCadastro]);

    //Loga o usuário
    const handleEsqueci = (form) => {
        form.preventDefault();
        firebase.auth()
            .sendPasswordResetEmail(emailSenha)
            .then(() => {
                setEmailSenha('');
                setOpenSenha(false);
                alert('Email enviado com sucesso!')
            })
            .catch((error) => alert(error.message));
    };

    //Abre modal de recuperação de senha
    const openModal = () => {
        if (email)
            setEmailSenha(email);
        setOpenSenha(true)
    };

    //Fecha modal de recuperação de senha
    const closeModal = () => {
        setEmailSenha('');
        setOpenSenha(false);
    };

    const handleVerifyEmail = () => {
        firebase.auth().currentUser
            .sendEmailVerification()
            .then((res) => {
                setOpenEmail(false);
                alert('Email reenviado com sucesso. Verifique sua caixa de entrada!')
            })
            .catch(err => console.log(err));
    };

    //Loga o usuário
    const handleLogin = (form) => {
        form.preventDefault();
        setEnviando(true);
        if (senha.length < 8 || senha.length > 20) {
            setEnviando(false);
            setMessage("A senha digitada tem que ter no minimo 8 e no máximo 20 caracteres");
            setSenha('');
            setOpen(true);
            senhaRef.current.focus();
        }
        else {
            let pass = CryptoJS.SHA1(senha);

            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

            firebase.auth()
                .signInWithEmailAndPassword(email, pass.toString())
                .then(({ user }) => {
                    if (!user.emailVerified) {
                        setMessage("Por favor verifique seu email antes de logar!");
                        setSenha('');
                        setEnviando(false);
                        setOpen(true);
                        setOpenEmail(true);
                        return;
                    };

                    user
                        .getIdToken(true)
                        .then((idToken) => postLogin(idToken, user.uid))
                        .catch((err) => {
                            setEnviando(false);
                            if (err.response.data) {
                                alert(err.response.data);
                            }
                            else
                                alert(err.response);
                        });
                })
                .catch((err) => {
                    setEnviando(false);
                    if (err.message === 'The password is invalid or the user does not have a password.') {
                        setMessage("Senha digitada incorreta ou usuário não existe");
                        setOpen(true);
                        setSenha('');
                        senhaRef.current.focus();
                    }
                    else if (err.message === "There is no user record corresponding to this identifier. The user may have been deleted.") {
                        setMessage("Não existe usuário cadastrado com este email");
                        setOpen(true);
                        setEmail('');
                        setSenha('');
                        emailRef.current.focus();
                    }
                    else if (err.message === "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.") {
                        setMessage("Conta bloqueada devido a muitas tentativas de login");
                        setOpen(true);
                        setEmail('');
                        setSenha('');
                        emailRef.current.focus();
                    }
                    else
                        alert(err.message);
                });
        }
    };

    //Loga o usuário com o google
    const handleLoginGoogle = () => {
        var google = new firebase.auth.GoogleAuthProvider();
        google.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
        google.addScope('https://www.googleapis.com/auth/user.birthday.read');
        firebase.auth().useDeviceLanguage();
        google.addScope('profile');
        google.addScope('openid');
        google.addScope('email');
        firebase.auth().signInWithRedirect(google);
        sessionStorage.setItem('redirect', true);
    };

    //Loga o usuário com o facebook
    const handleLoginFace = () => {
        var facebook = new firebase.auth.FacebookAuthProvider();
        facebook.addScope('user_birthday');
        facebook.addScope('public_profile');
        facebook.addScope('email');
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithRedirect(facebook);
        sessionStorage.setItem('redirect', true);
    };

    const handleLoginApple = () => {
        const apple = new firebase.auth.OAuthProvider('apple.com');
        apple.addScope('email');
        apple.addScope('name');
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithRedirect(apple);
        sessionStorage.setItem('redirect', true);
    };

    //Verifica e posta o resto das infos do cadastro do usuário
    const handleSignUp = (form) => {
        form.preventDefault();
        if (!validate(cpf)) {
            setMessageForm("O cpf digitado é invalido");
            setCPF('');
            setOpenAlertForm(true);
            cpfRef.current.focus();
        }
        else if (tel.length < 10) {
            setMessageForm("O telefone digitado é invalido");
            setOpenAlertForm(true);
            telRef.current.focus();
        }
        else if (nome.indexOf(' ') < 0) {
            setMessageForm("Por favor digite seu nome e sobrenome");
            setOpenAlertForm(true);
            nomeRef.current.focus();
        }
        else if (verificaIdade(niver) < 18) {
            setMessageForm("Apenas maiores de 18 anos podem se cadastrar");
            setNiver(null);
            setOpenAlertForm(true);
            niverRef.current.focus();
        }
        else {
            let json = '';
            let pass = CryptoJS.SHA1(senha);
            let niverFormatado = format(niver, 'yyyy-MM-dd');
            setEnviandocad(true);

            //Função para padronizar as especialidades
            const formataEspecialidade = (string) => {
                string = string.trim().toLowerCase();
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
            if (type.get("tipo") === "medico") {
                json = {
                    "name": nome,
                    "uid": userId,
                    "email": emailcad,
                    "phone": parseInt(tel),
                    "password": pass.toString(),
                    "crm": parseInt(crm),
                    "specialty": formataEspecialidade(esp.title),
                    "uf": UF,
                    "assistents": [],
                    "cpf": cpf,
                    "birth": niverFormatado,
                    "subscription": 1,
                    type: type.get("tipo")
                };
            }
            else if (type.get("tipo") === "assistente") {
                json = {
                    "name": nome,
                    "uid": userId,
                    "email": emailcad,
                    "phone": parseInt(tel),
                    "password": pass.toString(),
                    "cpf": cpf,
                    "medics": [],
                    "birth": niverFormatado,
                    "subscription": 1,
                    type: type.get("tipo")
                };
            }

            api
                .post('/create', json)
                .then((response) => {
                    setEnviandocad(false);
                    if (response.data === 'The email address is already in use by another account.') {
                        setMessageForm("Este email já está sendo usado por outro usuário");
                        setOpenAlertForm(true);
                        setEmail('');
                        emailcad.current.focus();
                    }
                    else {
                        postLogin(token, userId);
                    }
                })
                .catch((err) => {
                    setEnviandocad(false);
                    if (err.response) {
                        if (err.response.data) {
                            alert(err.response.data);
                        }
                        else
                            alert(err.response);
                    }
                    else
                        alert(err);
                });
        }
    };

    if (type.get("tipo")) {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    {social &&
                        <div className="loadingLoginSocialdiv">
                            <CircularProgress className="loadingLoginSocial" />
                        </div>
                    }
                    <div className="loginContainer">
                        <Collapse in={open} className="Alerta">
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={() => { setOpen(false) }}
                            >
                                {message}
                            </Alert>
                        </Collapse>
                        <Link to='/escolhe_usuario'>
                            <KeyboardBackspaceIcon className="voltarIcone" />
                        </Link>
                        <div className="loginTitulo">
                            <p>Login</p>
                        </div>

                        <div className="loginDivider"></div>

                        <form className="loginForm" onSubmit={handleLogin}>
                            <TextField
                                id="email"
                                type="email"
                                value={email}
                                onChange={(n) => setEmail(n.target.value)}
                                variant="outlined"
                                inputRef={emailRef}
                                autoComplete="email"
                                label="Email"
                                required
                                className="inputLogin"
                            />
                            <TextField
                                id="senha"
                                type="password"
                                value={senha}
                                inputRef={senhaRef}
                                autoComplete="current-password"
                                onChange={(n) => setSenha(n.target.value)}
                                variant="outlined"
                                label="Senha"
                                required
                                className="inputLogin"
                            />
                            <p onClick={() => openModal()} className="esqueciLogin">Esqueceu sua senha?</p>

                            <div className="divIconsLogin">
                                <div className="gIconLogin" onClick={() => handleLoginGoogle()} />
                                <FacebookIcon className="fIconLogin" onClick={() => handleLoginFace()} />
                                <AppleIcon className="AIconLogin" onClick={() => handleLoginApple()} />
                            </div>

                            <div className="textoCadastro">
                                <p>Não possui uma conta?</p>
                                <Link to={'/cadastro?' + type} style={{ textDecoration: 'none' }}>Cadastre-se aqui</Link>
                            </div>

                            <button disabled={enviando} className="btnLogin" style={enviando ? { padding: 5 } : { padding: 10 }}>
                                {enviando && <CircularProgress className="loadingLogin" />}
                                {!enviando && 'Prosseguir'}
                            </button>
                        </form>
                    </div>

                    {/* Modal de conclusão do cadastro */}
                    <Dialog
                        open={openCad}
                        onClose={() => setOpenCad(false)}
                        disableBackdropClick
                        TransitionComponent={Transition}
                        aria-labelledby="form-dialog-title"
                    >
                        <Collapse in={openAlertForm} className="Alerta">
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={() => { setOpenAlertForm(false) }}
                            >
                                {messageForm}
                            </Alert>
                        </Collapse>
                        <form onSubmit={handleSignUp}>
                            <DialogTitle id="form-dialog-title">Por favor, termine o seu cadastro</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Insira seus dados abaixo:
                                </DialogContentText>
                                <TextField
                                    id="nome"
                                    type="name"
                                    inputRef={nomeRef}
                                    variant="outlined"
                                    label="Nome Completo"
                                    required
                                    margin='dense'
                                    value={nome}
                                    onChange={(n) => setNome(n.target.value)}
                                    className={type.get("tipo") === 'medico' ? "loginModalInput" : 'loginModalInputAssis'}
                                />
                                <TextField
                                    id="emailcad"
                                    type="email"
                                    inputRef={emailcadRef}
                                    autoComplete="email"
                                    variant="outlined"
                                    label="Email"
                                    required
                                    margin='dense'
                                    value={emailcad}
                                    onChange={(n) => setEmailcad(n.target.value)}
                                    className={type.get("tipo") === 'medico' ? "loginModalInputEsq" : ''}
                                />
                                <div>
                                    <TextField
                                        id="tel"
                                        label="Telefone"
                                        name="tel"
                                        inputRef={telRef}
                                        variant="outlined"
                                        margin='dense'
                                        value={tel}
                                        onChange={(n) => setTel(n.target.value)}
                                        InputProps={{
                                            inputComponent: TelefoneCustom,
                                        }}
                                        required
                                        className={type.get("tipo") === 'medico' ? "loginModalInput" : 'loginModalInputAssis'}
                                    />
                                    <TextField
                                        id="cpf"
                                        value={cpf}
                                        inputRef={cpfRef}
                                        onChange={(n) => setCPF(n.target.value)}
                                        label="CPF"
                                        margin='dense'
                                        name="cpf"
                                        variant="outlined"
                                        InputProps={{
                                            inputComponent: CPFCustom,
                                        }}
                                        required
                                        className={type.get("tipo") === 'medico' ? "loginModalInputEsq" : ''}
                                    />
                                    {type.get("tipo") === 'medico' &&
                                        <Autocomplete
                                            id="esp"
                                            value={esp}
                                            onChange={(event, newValue) => {
                                                if (typeof newValue === 'string') {
                                                    setEsp({
                                                        title: newValue,
                                                    });
                                                } else if (newValue && newValue.inputValue) {
                                                    setEsp({
                                                        title: newValue.inputValue,
                                                    });
                                                } else {
                                                    setEsp(newValue);
                                                }
                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                // Suggest the creation of a new value
                                                if (params.inputValue !== '') {
                                                    filtered.push({
                                                        inputValue: params.inputValue,
                                                        title: `Add "${params.inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            options={especialidade}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option.title;
                                            }}
                                            renderOption={(option) => option.title}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} required Inputref={espRef}
                                                    label="Especialidade" margin='dense'
                                                    variant="outlined" />
                                            )}
                                        />}
                                </div>
                                <div className={type.get("tipo") === 'medico' ? 'loginModalDivCRM' : ''}>
                                    {type.get("tipo") === 'medico' &&
                                        <>
                                            <TextField
                                                id="crm"
                                                value={crm}
                                                inputRef={crmRef}
                                                onChange={(n) => setCRM(n.target.value)}
                                                label="CRM"
                                                name="crm"
                                                margin='dense'
                                                variant="outlined"
                                                type="number"
                                                required
                                                className={type.get("tipo") === 'medico' ? 'loginCRM' : ''}
                                            />
                                            <FormControl variant="outlined" margin='dense' className={type.get("tipo") === 'medico' ? 'loginModalInput loginUF' : ''}>
                                                <InputLabel id="UF-label">UF</InputLabel>
                                                <Select
                                                    id="UF"
                                                    margin='dense'
                                                    inputRef={UFRef}
                                                    value={UF}
                                                    onChange={(n) => setUF(n.target.value)}
                                                    required
                                                    labelId="UF-label"
                                                    label="UF"
                                                >
                                                    <MenuItem value={"AC"}>AC</MenuItem>
                                                    <MenuItem value={"AL"}>AL</MenuItem>
                                                    <MenuItem value={"AP"}>AP</MenuItem>
                                                    <MenuItem value={"AM"}>AM</MenuItem>
                                                    <MenuItem value={"BA"}>BA</MenuItem>
                                                    <MenuItem value={"CE"}>CE</MenuItem>
                                                    <MenuItem value={"DF"}>DF</MenuItem>
                                                    <MenuItem value={"ES"}>ES</MenuItem>
                                                    <MenuItem value={"GO"}>GO</MenuItem>
                                                    <MenuItem value={"MA"}>MA</MenuItem>
                                                    <MenuItem value={"MT"}>MT</MenuItem>
                                                    <MenuItem value={"MS"}>MS</MenuItem>
                                                    <MenuItem value={"MG"}>MG</MenuItem>
                                                    <MenuItem value={"PA"}>PA</MenuItem>
                                                    <MenuItem value={"PB"}>PB</MenuItem>
                                                    <MenuItem value={"PR"}>PR</MenuItem>
                                                    <MenuItem value={"PE"}>PE</MenuItem>
                                                    <MenuItem value={"PI"}>PI</MenuItem>
                                                    <MenuItem value={"RR"}>RR</MenuItem>
                                                    <MenuItem value={"RO"}>RO</MenuItem>
                                                    <MenuItem value={"RJ"}>RJ</MenuItem>
                                                    <MenuItem value={"RN"}>RN</MenuItem>
                                                    <MenuItem value={"RS"}>RS</MenuItem>
                                                    <MenuItem value={"SC"}>SC</MenuItem>
                                                    <MenuItem value={"SP"}>SP</MenuItem>
                                                    <MenuItem value={"SE"}>SE</MenuItem>
                                                    <MenuItem value={"TO"}>TO</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </>
                                    }
                                    <div className={type.get("tipo") === 'medico' ? '' : ''}>
                                        <KeyboardDatePicker
                                            id="niver"
                                            value={niver}
                                            inputRef={niverRef}
                                            onChange={(n) => setNiver(n)}
                                            openTo="year"
                                            views={["year", "month", "date"]}
                                            margin='dense'
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            label="Data de nascimento"
                                            required
                                            disableFuture='true'
                                            className={type.get("tipo") === 'medico' ? "birthday" : ''}
                                        />
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {!enviando &&
                                    <Button onClick={() => setOpenCad(false)} color="primary">
                                        Cancelar
                                    </Button>
                                }
                                <Button type="submit" disabled={enviandocad} color="primary" style={enviandocad ? { width: 150 } : {}}>
                                    {enviandocad && <CircularProgress className="loadingCadLogin" />}
                                    {!enviandocad && 'Cadastrar'}
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    {/* Modal esqueci minha senha */}
                    <Dialog open={openSenha} onClose={() => closeModal()} aria-labelledby="form-dialog-title">
                        <form onSubmit={handleEsqueci}>
                            <DialogTitle id="form-dialog-title">Recuperar Senha</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Insira o email que você utiliza na sua conta abaixo:
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="email"
                                    variant="outlined"
                                    label="Email"
                                    type="email"
                                    value={emailSenha}
                                    onChange={(e) => setEmailSenha(e.target.value)}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => closeModal()} color="primary">
                                    Cancelar
                                </Button>
                                <Button type="submit" color="primary">
                                    Enviar
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    {/* Modal reenviar confirmação de email */}
                    <Dialog open={openEmail} onClose={() => setOpenEmail(false)} disableBackdropClick aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Verificação de Email</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Verifique sua caixa de entrada e caixa de spam, caso não ache seu email de verificação clique abaixo para que ele seja reenviado.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenEmail(false)} color="primary">
                                Achei
                            </Button>
                            <Button type="submit" onClick={() => handleVerifyEmail()} color="primary">
                                Reenviar meu email
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </MuiPickersUtilsProvider>
        );
    }
    else {
        return (
            <Redirect to={"/escolhe_usuario"} />
        )
    }
}
export default Login;