import Navbar2 from '../../components/navbar2/index';
import Footer from '../../components/footer/index';
import Celular from "./../../assets/celular.jpg";
import React, { useEffect } from 'react';
import './home.css';

//Tela Inicial da plataforma e sobre da empresa
function Home() {

  //Sobe a pagina depois que carrega
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="homeContainer">
      <Navbar2 />

      {/* Sobre */}
      <section className="homeSobre">
        {/* Titulo do Sobre */}
        <div className="homeSobreTitulo">
          <p>Quem somos?</p>
        </div>

        {/* Primeria parte do sobre */}
        <div className="homeSobreParte1">
          <div className="textoquemsomos">
            <p>Somos uma equipe de pessoas e inteligência artificial que auxilia técnicas de gestão da informação para o controle e melhorar as decisões de profissionais da saúde, de maneira que suas finanças “andem de vento em popa”. Nossas decisões levam em conta cada fator, focando em exatidão dos dados porque entendemos a realidade de quem precisa de informações financeiras organizadas. Controlar seus recebimentos é o primeiro passo para uma vida financeira mais segura.
            </p>
          </div>
          <div className="quadrado" />
        </div>

        {/* Secunda parte do sobre */}
        <div className="homeSobreParte2">
          <div>
            <div className="quadrado1" />
            <div className="quadrado2" />
          </div>
          <div className="textoquemsomos2">
            <p>Assim, prezamos pela praticidade, facilitando e otimizando a rotina dos nossos clientes, pois entendemos que o tempo e esforço dos profissionais da saúde são valiosos e precisa ser direcionado para sua atividade fim. Nesse sentido, visamos a transparência, segurança e precisão em registrar valores para você, de maneira independente, controlar e realizar a cobrança dos seus atendimentos junto às clínicas e hospitais.
            </p>
          </div>
        </div>
      </section>

      {/* Sobre Aplicativo */}
      <section className="homeApp">
        {/* Titulo seção do aplicativo */}
        <div className="homeAppTitulo">
          <p>O aplicativo</p>
        </div>

        <div className="homeAppContent">
          <div>
            <div className="quadrado3" />
          </div>
          <div className="homeAppTexto">
            <p>O aplicativo CashCare para ajudar profissionais da saúde a organizar os valores que tem a receber pelos plantões e diversos atendimentos realizados.
            </p>
            <p>
              Muitas vezes o profissional da saúde, como o médico e o dentista, por exemplo, faz plantões ou realizam atendimentos rotineiros, mas no final do mês deixam de receber o seu precioso dinheiro porque o hospital ou a clínica não fez o devido controle.
            </p>
            <p>
              Ou até mesmo, a gente esquece de fazer nossas anotações. Pensando nisso, o aplicativo CashCare surge para ajudar nesse controle de maneira simples, deixando você com o controle dos seus recebimentos na palma da sua mão.
            </p>
          </div>
          <div>
            <img className="homeAppImagem" src={Celular} alt="App no Celular"></img>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
