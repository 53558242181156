import { DialogContentText, DialogActions, DialogContent, DialogTitle, Button, Dialog, CircularProgress, Tooltip } from '@material-ui/core';
import { formataTelefone } from '../../services/utils';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import React, { useState, memo } from 'react';
import api from '../../services/api';
import './card_assistente.css';

//Card de usuário da equipe, usado na pagina equipe
function CardAssistente({ uid, nome, email, telefone, especialidade, state, removedor, stateUpdater }) {
  const [open, setOpen] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const history = useHistory();
  const [tipo] = useState(() => {
    if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
      return true
    else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
      return false
    else {
      api
        .post('/logout')
        .then(() => {
          sessionStorage.clear();
          history.replace('/');
        })
        .catch((err) => console.log(err));
    }
  });

  const handleAprovar = () => {
    let json = {
      medic: JSON.parse(sessionStorage.getItem('data')).uid,
      assistant: uid,
    };
    api
      .patch('/medico/approve', json)
      .then(() => stateUpdater(uid))
      .catch((err) => alert(err));
  };

  const handleAceitar = () => {
    setEnviando(true);

    let json = '';
    if (!tipo)
      json = {
        medic: email,
        assistent: JSON.parse(sessionStorage.getItem('data')).uid,
        type: JSON.parse(sessionStorage.getItem('data')).userTipo,
        state: true
      };
    else if (tipo)
      json = {
        medic: JSON.parse(sessionStorage.getItem('data')).uid,
        assistent: email,
        type: JSON.parse(sessionStorage.getItem('data')).userTipo,
        state: state
      };

    api
      .patch('/removeLink/email', json)
      .then(() => {
        setEnviando(false);
        setOpen(false);
        removedor(uid);
      })
      .catch((err) => {
        setEnviando(false);
        if (err.response) {
          if (err.response.data) {
            if (err.response.data === "Unauthorized") {
              api
                .post('/logout')
                .then(() => {
                  sessionStorage.clear();
                  window.location.replace('/');
                })
                .catch((err) => console.log(err));
            }
            else
              alert(err.response.data);
          }
          else
            alert(err.response);
        }
        else
          alert(err);
      })
  };

  return (
    <div className="cardEquipe">
      <div className="divIconEquipeCardf">
        <Tooltip title="Remover da Equipe">
          <CloseIcon onClick={() => setOpen(true)} className='apagarEquipe' style={{ fontSize: 25, color: 'white' }} />
        </Tooltip>
      </div>
      <div className="cardEquipeInfo">
        <p>{nome}</p>
        <p>{email}</p>
        <p>{formataTelefone(telefone.toString())}</p>
        {especialidade &&
          <p>{especialidade}</p>
        }
        {tipo && !state &&
          <div className="cardEquipeAprovarDiv">
            <button className="cardEquipeAprovarButton" onClick={handleAprovar}>Aprovar</button>
            <button className="cardEquipeRemoverButton" onClick={handleAceitar}>Remover</button>
          </div>
        }
      </div>

      {/* modal */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
            Você tem certeza que deseja remover esse(a) pessoa da sua equipe?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!enviando &&
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
          }
          <Button onClick={handleAceitar} disabled={enviando} color="primary" style={enviando ? { width: 150 } : {}}>
            {enviando && <CircularProgress className="loadingCardEquipe" />}
            {!enviando && 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(CardAssistente);