import Rotas from './services/routes/Routes';
import firebase from "firebase/app";
import ReactDOM from 'react-dom';
import 'firebase/analytics';
import React from 'react';
import './index.css';

firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
});

ReactDOM.render(
    <React.StrictMode>
        <Rotas />
    </React.StrictMode>,
    document.getElementById('root')
);