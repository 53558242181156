export const MenuItens =[
    {
        title: 'Cadastrar Atendimento',
        url: '/cadastro_atendimento',
        cName: 'nav-links'
    },

    {
        title: 'Menu',
        url: '/menu',
        cName: 'nav-links'
    },

    {
        title: 'Clínicas',
        url: '/clinicas',
        cName: 'nav-links'
    },

]