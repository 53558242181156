import { TextField, MenuItem, Select, FormControl, InputLabel, Collapse, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete, createFilterOptions, Alert } from '@material-ui/lab';
import { Link, useLocation, useHistory, Redirect } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { TelefoneCustom, CPFCustom } from '../../services/mascaras';
import { verificaIdade } from '../../services/utils';
import React, { useState, useEffect, useRef } from 'react';
import { validate } from 'gerador-validador-cpf';
import DateFnsUtils from '@date-io/date-fns';
import api from '../../services/api';
import PropTypes from "prop-types";
import { format } from 'date-fns';
import CryptoJS from "crypto-js";
import './cadastro.css';
import 'date-fns';

//Definição das props das mascaras
TelefoneCustom.propTypes = CPFCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

//Parte do Autocomplete
const filter = createFilterOptions();

function Cadastro() {
  //type vê se o usuário escolheu médico ou assistente
  let type = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [enviando, setEnviando] = useState(false);
  const tipo = type.get("tipo") === "medico" ? true : false;

  //Array de especialidades
  const [especialidade, setEspecialidade] = useState([]);

  //Consts das infos do usuário
  const [nome, setNome] = useState('');
  const nomeRef = useRef(null);
  const [email, setEmail] = useState('');
  const emailRef = useRef(null);
  const [tel, setTel] = useState('');
  const telRef = useRef(null);
  const [esp, setEsp] = useState('');
  const espRef = useRef(null);
  const [cpf, setCPF] = useState('');
  const cpfRef = useRef(null);
  const [niver, setNiver] = useState(null);
  const niverRef = useRef(null);
  const [crm, setCRM] = useState('');
  const crmRef = useRef(null);
  const [UF, setUF] = useState('');
  const UFRef = useRef(null);
  const [senha, setSenha] = useState('');
  const senhaRef = useRef(null);
  const [senhaConf, setSenhaConf] = useState('');
  const senhaConfRef = useRef(null);

  //Consts do alerta
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    //Se o usuário for médico pega as especialidades pro autocomplete
    if (tipo)
      api.get('/specialty').then(response => {
        let espUnicas = response.data.map((esp) => {
          return {
            title: esp,
            value: esp
          }
        })
        setEspecialidade(espUnicas);
      })
  }, [tipo]);

  const handleSignUp = (form) => {
    form.preventDefault();
    if (senha !== senhaConf) {
      setMessage("As senhas digitadas são diferentes");
      setSenhaConf('')
      setOpen(true);
      senhaConfRef.current.focus();
    }
    else if (senha.length < 8 || senha.length > 20) {
      setMessage("A senha digitada tem que ter no minimo 8 e no máximo 20 caracteres");
      setSenha('');
      setSenhaConf('');
      setOpen(true);
      senhaRef.current.focus();
    }
    else if (!validate(cpf)) {
      setMessage("O cpf digitado é invalido");
      setCPF('');
      setOpen(true);
      cpfRef.current.focus();
    }
    else if (tel.length < 10) {
      setMessage("O telefone digitado é invalido");
      setOpen(true);
      telRef.current.focus();
    }
    else if (nome.indexOf(' ') < 0) {
      setMessage("Por favor digite seu nome e sobrenome");
      setOpen(true);
      nomeRef.current.focus();
    }
    else if (verificaIdade(niver) < 18) {
      setMessage("Apenas maiores de 18 anos podem se cadastrar");
      setNiver(null);
      setOpen(true);
      niverRef.current.focus();
    }
    else {
      let json = '';
      let pass = CryptoJS.SHA1(senha);
      let niverFormatado = format(niver, 'yyyy-MM-dd');
      setEnviando(true);

      //Função para padronizar as especialidades
      const formataEspecialidade = (string) => {
        string = string.trim().toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      if (type.get("tipo") === "medico") {
        json = {
          "type": 'medico',
          "name": nome,
          "email": email,
          "phone": parseInt(tel),
          "password": pass.toString(),
          "crm": parseInt(crm),
          "specialty": formataEspecialidade(esp.title),
          "uf": UF,
          "assistents": [],
          "cpf": cpf,
          "birth": niverFormatado,
        };
      }
      else if (type.get("tipo") === "assistente") {
        json = {
          "type": 'assistente',
          "name": nome,
          "email": email,
          "phone": parseInt(tel),
          "password": pass.toString(),
          "cpf": cpf,
          "medics": [],
          "birth": niverFormatado,
        };
      }

      api
        .post('/create', json)
        .then((response) => {
          setEnviando(false);
          if (response.data === 'The email address is already in use by another account.') {
            setMessage("Este email já está sendo usado por outro usuário");
            setOpen(true);
            setEmail('');
            emailRef.current.focus();
          }
          else {
            history.push('/login?' + type);
          }
        })
        .catch((err) => {
          setEnviando(false);
          if (err.response) {
            if (err.response.data)
              alert(err.response.data);
            else
              alert(err.response);
          }
          else
            alert(err);
        })
    }
  }

  //Render de usuário médico
  if (type.get("tipo") === "medico") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <Collapse in={open} className="Alerta">
          <Alert
            variant="filled"
            severity="error"
            onClose={() => { setOpen(false) }}
          >
            {message}
          </Alert>
        </Collapse>

        <div className="divCadastro">
          <Link to={'/login?' + type}>
            <KeyboardBackspaceIcon className="voltarIcone" />
          </Link>
          <div className="divCadastroTitulo">
            <p>Cadastro</p>
          </div>

          <div className="CadastroDivider" />

          <form className="divCadastroForm" onSubmit={handleSignUp}>
            <TextField
              id="nome"
              type="name"
              inputRef={nomeRef}
              variant="outlined"
              label="Nome Completo"
              required
              value={nome}
              onChange={(n) => setNome(n.target.value)}
              className="inputCadastro"
            />
            <TextField
              id="email"
              type="email"
              inputRef={emailRef}
              autoComplete="email"
              variant="outlined"
              label="Email"
              required
              value={email}
              onChange={(n) => setEmail(n.target.value)}
              className="inputCadastro"
            />
            <div>
              <TextField
                id="tel"
                label="Telefone"
                inputRef={telRef}
                name="tel"
                variant="outlined"
                value={tel}
                onChange={(n) => setTel(n.target.value)}
                InputProps={{
                  inputComponent: TelefoneCustom,
                }}
                required
                className="inputCadastro Tel"
              />
              <Autocomplete
                id="esp"
                value={esp}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setEsp({
                      title: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setEsp({
                      title: newValue.inputValue,
                    });
                  } else {
                    setEsp(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={especialidade}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderOption={(option) => option.title}
                freeSolo
                className="inputCadastro esp"
                renderInput={(params) => (
                  <TextField {...params} required inputRef={espRef}
                    label="Especialidade" variant="outlined" />
                )}
              />
            </div>
            <div>
              <TextField
                id="cpf"
                value={cpf}
                inputRef={cpfRef}
                onChange={(n) => setCPF(n.target.value)}
                label="CPF"
                name="cpf"
                variant="outlined"
                InputProps={{
                  inputComponent: CPFCustom,
                }}
                required
                className="inputCadastro CPF"
              />
              <KeyboardDatePicker
                id="niver"
                value={niver}
                inputRef={niverRef}
                onChange={(n) => setNiver(n)}
                openTo="year"
                views={["year", "month", "date"]}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                label="Data de aniversário"
                required
                disableFuture='true'
                className="inputCadastro Niver"
              />
            </div>
            <div>
              <TextField
                id="crm"
                value={crm}
                inputRef={crmRef}
                onChange={(n) => setCRM(n.target.value)}
                label="CRM"
                name="crm"
                variant="outlined"
                type="number"
                required
                className="inputCadastro CRM"
              />
              <FormControl variant="outlined" className="inputCadastro UF">
                <InputLabel id="UF-label">UF</InputLabel>
                <Select
                  id="UF"
                  value={UF}
                  inputRef={UFRef}
                  onChange={(n) => setUF(n.target.value)}
                  required
                  labelId="UF-label"
                  label="UF"
                >
                  <MenuItem value={"AC"}>AC</MenuItem>
                  <MenuItem value={"AL"}>AL</MenuItem>
                  <MenuItem value={"AP"}>AP</MenuItem>
                  <MenuItem value={"AM"}>AM</MenuItem>
                  <MenuItem value={"BA"}>BA</MenuItem>
                  <MenuItem value={"CE"}>CE</MenuItem>
                  <MenuItem value={"DF"}>DF</MenuItem>
                  <MenuItem value={"ES"}>ES</MenuItem>
                  <MenuItem value={"GO"}>GO</MenuItem>
                  <MenuItem value={"MA"}>MA</MenuItem>
                  <MenuItem value={"MT"}>MT</MenuItem>
                  <MenuItem value={"MS"}>MS</MenuItem>
                  <MenuItem value={"MG"}>MG</MenuItem>
                  <MenuItem value={"PA"}>PA</MenuItem>
                  <MenuItem value={"PB"}>PB</MenuItem>
                  <MenuItem value={"PR"}>PR</MenuItem>
                  <MenuItem value={"PE"}>PE</MenuItem>
                  <MenuItem value={"PI"}>PI</MenuItem>
                  <MenuItem value={"RR"}>RR</MenuItem>
                  <MenuItem value={"RO"}>RO</MenuItem>
                  <MenuItem value={"RJ"}>RJ</MenuItem>
                  <MenuItem value={"RN"}>RN</MenuItem>
                  <MenuItem value={"RS"}>RS</MenuItem>
                  <MenuItem value={"SC"}>SC</MenuItem>
                  <MenuItem value={"SP"}>SP</MenuItem>
                  <MenuItem value={"SE"}>SE</MenuItem>
                  <MenuItem value={"TO"}>TO</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                id="senha"
                value={senha}
                inputRef={senhaRef}
                onChange={(n) => setSenha(n.target.value)}
                label="Senha"
                autoComplete="new-password"
                name="senha"
                type="password"
                variant="outlined"
                required
                className="inputCadastro senha-e"
              />
              <TextField
                id="senha_conf"
                value={senhaConf}
                inputRef={senhaConfRef}
                onChange={(n) => setSenhaConf(n.target.value)}
                label="Confirme sua senha"
                autoComplete="new-password"
                name="senha_conf"
                type="password"
                variant="outlined"
                required
                className="inputCadastro senha"
              />
            </div>
            <button type="submit" disabled={enviando} className="Cadastrobtn" style={enviando ? { padding: 6 } : { padding: 10 }}>
              {enviando && <CircularProgress className="loadingLogin" />}
              {!enviando && 'Prosseguir'}
            </button>
          </form>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  //Render de usuário assistente
  else if (type.get("tipo") === "assistente") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <Collapse in={open} className="Alerta">
          <Alert
            variant="filled"
            severity="error"
            onClose={() => { setOpen(false) }}
          >
            {message}
          </Alert>
        </Collapse>

        <div className="divCadastro">
          <Link to={'/login?' + type}>
            <KeyboardBackspaceIcon className="voltarIcone" />
          </Link>
          <div className="divCadastroTitulo">
            <p>Cadastro</p>
          </div>

          <div className="CadastroDivider" />

          <form className="divCadastroForm" onSubmit={handleSignUp}>
            <TextField
              id="nome"
              type="name"
              inputRef={nomeRef}
              variant="outlined"
              label="Nome Completo"
              required
              value={nome}
              onChange={(n) => setNome(n.target.value)}
              className="inputCadastro"
            />
            <div className="divCadastroEmail">
              <TextField
                id="email"
                type="email"
                inputRef={emailRef}
                autoComplete="email"
                variant="outlined"
                label="Email"
                required
                value={email}
                onChange={(n) => setEmail(n.target.value)}
                className="inputCadastro email"
              />
              <TextField
                id="tel"
                label="Telefone"
                inputRef={telRef}
                name="tel"
                variant="outlined"
                value={tel}
                onChange={(n) => setTel(n.target.value)}
                InputProps={{
                  inputComponent: TelefoneCustom,
                }}
                required
                className="inputCadastro Tel2"
              />
            </div>
            <div>
              <TextField
                id="cpf"
                value={cpf}
                inputRef={cpfRef}
                onChange={(n) => setCPF(n.target.value)}
                label="CPF"
                name="cpf"
                variant="outlined"
                InputProps={{
                  inputComponent: CPFCustom,
                }}
                required
                className="inputCadastro CPF"
              />
              <KeyboardDatePicker
                id="niver"
                value={niver}
                inputRef={niverRef}
                onChange={(n) => setNiver(n)}
                openTo="year"
                views={["year", "month", "date"]}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                label="Data de aniversário"
                required
                disableFuture='true'
                className="inputCadastro Niver"
              />
            </div>
            <div>
              <TextField
                id="senha"
                value={senha}
                inputRef={senhaRef}
                onChange={(n) => setSenha(n.target.value)}
                label="Senha"
                autoComplete="new-password"
                name="senha"
                type="password"
                variant="outlined"
                required
                className="inputCadastro senha-e"
              />
              <TextField
                id="senha_conf"
                value={senhaConf}
                inputRef={senhaConfRef}
                onChange={(n) => setSenhaConf(n.target.value)}
                label="Confirme sua senha"
                autoComplete="new-password"
                name="senha_conf"
                type="password"
                variant="outlined"
                required
                className="inputCadastro senha"
              />
            </div>

            <button type="submit" disabled={enviando} className="Cadastrobtn" style={enviando ? { padding: 6 } : { padding: 10 }}>
              {enviando && <CircularProgress className="loadingLogin" />}
              {!enviando && 'Prosseguir'}
            </button>
          </form>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  //Se vier sem especificação do tipo de usuário
  else {
    return (
      <Redirect to={"/escolhe_usuario"} />
    )
  }
}

export default Cadastro;