import { Route, useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import api from '../../services/api';
import React from "react";

const cookies = new Cookies();

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const history = useHistory();

  const logout = (route) => {
    api
      .post('/logout')
      .then(() => {
        sessionStorage.clear();
        history.replace(route);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!JSON.parse(sessionStorage.getItem('data'))) {
          logout('/');
        }
        else if (!cookies.get('controller')) {
        // else if (!sessionStorage.getItem('token')) {
          let rota = '/'
          if (JSON.parse(sessionStorage.getItem('data'))) {
            rota = JSON.parse(sessionStorage.getItem('data')).userTipo ? ("/login?tipo=" + JSON.parse(sessionStorage.getItem("data")).userTipo) : '/';
            sessionStorage.clear();
          }
          logout(rota);
        }
        else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente' && routeProps.location.pathname === '/grafico') {
          history.replace('/menu');
        }
        else {
          return <RouteComponent {...routeProps} />
        }
      }}
    />
  );
};

export default PrivateRoute;