import { TextField, Slide, DialogContentText, DialogActions, DialogContent, Button, Dialog, Tooltip } from '@material-ui/core';
import React, { useState, memo, useCallback, useRef } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ValorCustom } from '../../services/mascaras';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import PropTypes from "prop-types";
import { format } from 'date-fns';
import './card_procedimentos.css';

//Definição das props das mascaras
ValorCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

//Efeito do modal
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//Card com informações dos procedimentos
function CardProcedimentos({ id, idClinica, idMedico, nomeMedico, emailMedico, clinica, endereco, data, paciente, tuss, plano, valores, valorPago, removedor }) {
  let history = useHistory();
  const [tipo] = useState(() => {
    if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
      return true
    else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
      return false
    else {
      api
        .post('/logout')
        .then(() => {
          sessionStorage.clear();
          history.replace('/');
        })
        .catch((err) => console.log(err));
    }
  });

  const TussArray = Array.isArray(tuss);
  const [open, setOpen] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [openDeletar, setOpenDeletar] = useState(false);
  const [openRemover, setOpenRemover] = useState(false);
  const [pago, setPago] = useState(() => {
    if (typeof valorPago === 'number')
      return valorPago > 0 ? true : false;
    else
      return (valorPago.reduce((a, b) => a + b, 0) > 0) ? true : false;
  });
  const [valorRecebido, setvalorRecebido] = useState((typeof valorPago === 'number') ?
    [valorPago].reduce((a, b) => a + b, 0).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    :
    valorPago.reduce((a, b) => a + b, 0).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  );
  const [confirmacao, setConfirmacao] = useState(true);
  const [texto, setTexto] = useState('Marcar atendimento como pago?');
  const [incompleto, setIncompleto] = useState(false);
  const [pagamento, setPagamento] = useState(() => {
    let temp = [];
    if (TussArray) {
      tuss.forEach(() => temp.push(0));
    } else {
      temp.push(0);
    }
    return temp;
  });
  const modConfimRef = useRef(null);
  const modEditRef = useRef(null);
  const modCancRef = useRef(null);
  const valor = ((typeof valores === 'number') ?
    [valores].reduce((a, b) => a + b, 0).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    :
    valores.reduce((a, b) => a + b, 0).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setIncompleto(false);
    setConfirmacao(true);
    setTexto('Marcar atendimento como pago?');
    setPagamento('');
  }, []);

  const handleDelete = useCallback(() => {
    api
      .delete('/procedimento/' + id)
      .then((res) => {
        setOpenDeletar(false);
        removedor(id);
      })
      .catch((err) => {
        setOpenDeletar(false);
        alert(err);
      });
  }, [id, removedor]);

  const handleCheck = () => pago ? setOpenRemover(true) : setOpen(true);

  //Faz o patch do valor pago e seta o checkbox como marcado
  const postPago = (remover) => {
    let enviar = [];
    if (remover === 1) {
      if (TussArray) {
        tuss.forEach(() => enviar.push(0));
      } else {
        enviar.push(0);
      }
    }
    else {
      if (remover === 2) {
        if (typeof valores === 'number')
          [valores].forEach((valorPagamento) => enviar.push(parseFloat(valorPagamento)))
        else
          valores.forEach((valorPagamento) => enviar.push(parseFloat(valorPagamento)))
      }
      else
        pagamento.forEach((valorPagamento) => enviar.push(parseFloat(valorPagamento)));
    }
    api
      .patch('/procedimento/pagamento/' + id, {
        received_value: enviar,
        email: emailMedico,
        procedure: JSON.stringify({
          clinica: clinica,
          endereco: endereco,
          data: data,
          paciente: paciente,
          tuss: tuss,
          plano: plano,
          valor: valores
        })
      })
      .then(() => {
        if (remover === 1) {
          setOpenRemover(false);
          setPago(false);
          setvalorRecebido(0);
        }
        else {
          handleClose();
          setPago(true);
          setvalorRecebido(() => {
            if (remover === 2) {
              return valor;
            }
            else if (TussArray) {
              return parseFloat(pagamento.reduce((a, b) => a + b, 0)).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
            else {
              return parseFloat(pagamento).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
          });
        }
      })
      .catch((err) => {
        if (err.response)
          if (err.response.data) {
            if (err.response.data === "Unauthorized") {
              api
                .post('/logout')
                .then(() => {
                  sessionStorage.clear();
                  window.location.replace('/');
                })
                .catch((err) => console.log(err));
            }
            else {
              alert(err.response.data);
            };
          }
          else {
            alert(err.response);
          }
        else {
          alert(err);
        }
      })
  }

  return (
    <div className="cardProcedimentoContainer">
      <div className="cardProcedimento">
        <div className="cardProcedTituloDiv">
          <p className="cardProcedTitulo">{paciente}</p>
        </div>
        <div className="cardProcedInfoDiv">
          <Tooltip title="Ver Atendimento">
            <VisibilityIcon onClick={() => history.push('/atendimento/' + id)} className='verProced' style={{ fontSize: 25, color: 'white' }} />
          </Tooltip>
          <Tooltip title="Editar Atendimento">
            <EditIcon onClick={() => setOpenEditar(true)} className='editarProced' style={{ fontSize: 25, color: 'white' }} />
          </Tooltip>
          <Tooltip title="Apagar Atendimento">
            <DeleteIcon onClick={() => setOpenDeletar(true)} className='apagarProced' style={{ fontSize: 25, color: 'white' }} />
          </Tooltip>
          <div>
            {!tipo &&
              <p>Médico: {nomeMedico}</p>
            }
            <p>Clínica: {clinica}</p>
            <p>Endereço: {endereco}</p>
            <p>Data: {format(new Date(data), "dd/MM/yyyy")}</p>
            <p>Paciente: {paciente}</p>
            <p>TUSS:
              {TussArray ?
                tuss.map((tussAtual, key) => {
                  if (key + 1 === tuss.length)
                    return ` ${tussAtual.code}`;
                  return ` ${tussAtual.code},`;
                })
                :
                ` ${tuss.code}`
              }
            </p>
            <p>Plano: {plano}</p>
            <p>Valor Cadastrado: R$ {valor}</p>
            {pago && tipo &&
              <p>Valor Recebido: R$ {valorRecebido}</p>
            }
          </div>
          <Tooltip title="Marcar como pago">
            <div
              type="checkbox"
              className="cardProcedCheck"
              style={pago ? { backgroundColor: '#00d4ce', borderColor: '#170647' } : {}}
              onClick={handleCheck}
            >
              {pago &&
                <CheckIcon style={pago ? { fontSize: 25, color: '#170647' } : { fontSize: 25 }} />
              }
            </div>
          </Tooltip>
        </div>
      </div>

      {/* Modal de Confirmação de Pagamento */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        ref={modConfimRef}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: 'black' }}>
            {texto}
          </DialogContentText>
          {confirmacao &&
            <div className="cardProcedDivBtns">
              <Button onClick={() => {
                setConfirmacao(false);
                setTexto("Você recebeu o pagamento completo?");
              }} color="primary">
                Sim
              </Button>
              <Button onClick={() => handleClose()} color="primary">
                Não
              </Button>
            </div>
          }
          {!confirmacao && !incompleto &&
            <div>
              <p style={{ margin: 0, marginBottom: 8, textAlign: 'center' }}>Valor cadastrado:  R$ {valor}</p>
              <div className="cardProcedDivBtns">
                <Button onClick={() => postPago(2)}
                  color="primary"
                >
                  Sim
                </Button>
                <Button onClick={() => {
                  setIncompleto(true);
                  setTexto("Quanto você recebeu?");
                }}
                  color="primary"
                >
                  Não
                </Button>
              </div>
            </div>
          }
          {incompleto &&
            <div className="cardProcedDivBtns">
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                {TussArray &&
                  tuss.map((tussAtual, key) =>
                    <TextField
                      color="primary"
                      value={pagamento[key] > 0 ? pagamento[key] : undefined}
                      onChange={(e) => {
                        let temp = [...pagamento];
                        temp[key] = e.target.value;
                        setPagamento(temp);
                      }}
                      variant="outlined"
                      margin="dense"
                      name='valorPago'
                      label={"TUSS: " + tussAtual.code}
                      InputProps={{ inputComponent: ValorCustom }}
                    />
                  )
                }
              </div>
              <DialogActions>
                <Button onClick={() => postPago()}
                  color="primary"
                >
                  Concluir
                </Button>
                <Button onClick={() => handleClose()} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
            </div>
          }
        </DialogContent>
      </Dialog>

      {/* Modal de Cancelar Pagamento */}
      <Dialog
        open={openRemover}
        TransitionComponent={Transition}
        keepMounted
        ref={modCancRef}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => setOpenRemover(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: 'black' }}>
            {"Você tem certeza que quer marcar este atendimento como não pago?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemover(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => postPago(1)} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Apagar Procedimento */}
      <Dialog
        open={openDeletar}
        TransitionComponent={Transition}
        keepMounted
        ref={modCancRef}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => setOpenDeletar(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: 'black' }}>
            {"Você tem certeza que quer apagar este atendimento?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeletar(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => handleDelete()} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Editar Procedimento */}
      <Dialog
        open={openEditar}
        TransitionComponent={Transition}
        keepMounted
        ref={modEditRef}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => setOpenEditar(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: 'black' }}>
            {"Você quer editar este atendimento?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditar(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => {
            history.push({
              pathname: "/cadastro_atendimento",
              state: {
                id: id,
                idClinica: idClinica,
                idMedico: idMedico,
                emailMedico: emailMedico,
                nomeMedico: nomeMedico,
                data: data,
                pacienteCad: paciente,
                tuss: tuss,
                planoSaude: plano,
                valores: valores,
                valorPago: valorPago
              }
            });
          }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
}

export default memo(CardProcedimentos);