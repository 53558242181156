import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TextField, CircularProgress, Collapse } from '@material-ui/core';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Autocomplete, Alert } from "@material-ui/lab";
import Navbar from '../../components/navbar/index';
import DateFnsUtils from '@date-io/date-fns';
import { Line, Bar } from 'react-chartjs-2';
import { ptBR } from 'date-fns/locale';
import api from '../../services/api';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import './grafico.css';

//Função que formata os datasets para preencher o gráfico
const preencheGrafico = (arr, dateInicial, dateFinal) => {
    let tempRecebidoGrafico = [];
    let tempReceberGrafico = [];
    let dateArr = [];
    let higherValue = 0;

    arr.forEach(pcd => dateArr.push(format(new Date(pcd.date), 'yyyy-MM-dd')));

    let dateUnicos = [...new Set(dateArr)];

    dateUnicos = Array.from(dateUnicos).sort((a, b) => new Date(a) - new Date(b));

    dateUnicos.forEach(data => {
        let valorRecebidoData = 0;
        let valorReceberData = 0;
        let temp = [];

        arr.forEach((pcd, index) => {
            let dataPcd = new Date(format(new Date(pcd.date), 'yyyy-MM-dd'));
            if (dataPcd <= dateFinal && dataPcd >= dateInicial) {
                if (dataPcd.valueOf() === new Date(data).valueOf()) {
                    temp.push(pcd);
                }
            }
        });
        temp.forEach((pcd, index) => {
            if (pcd.received_value > 0)
                valorRecebidoData += pcd.received_value;
            else
                valorReceberData += pcd.registered_value;
        });

        valorReceberData = parseFloat(valorReceberData).toFixed(2);
        valorRecebidoData = parseFloat(valorRecebidoData).toFixed(2);

        if (valorRecebidoData > 0) {
            if (valorRecebidoData > higherValue)
                higherValue = valorRecebidoData;
            tempRecebidoGrafico.push({
                x: new Date(data + 'T00:00:00.000-03:00'),
                y: valorRecebidoData
            })
        }
        if (valorReceberData > 0) {
            if (valorReceberData > higherValue)
                higherValue = valorReceberData;
            tempReceberGrafico.push({
                x: new Date(data + 'T00:00:00.000-03:00'),
                y: valorReceberData
            })
        }

    });
    let max = (higherValue + higherValue / 10);
    options.scales.y.max = (Math.round(max * 100) / 100).toFixed(2);

    return [
        {
            label: 'Receber',
            data: tempReceberGrafico,
            fill: false,
            backgroundColor: 'rgb(23, 6, 71)',
            borderColor: 'rgb(23, 6, 71)',
        },
        {
            label: 'Recebido',
            data: tempRecebidoGrafico,
            fill: true,
            backgroundColor: 'rgb(0, 212, 206)',
            borderColor: 'rgba(0, 158, 153, 1)',
        }
    ];
}

//Const de opções do gráfico
const options = {
    animation: false,
    tension: 0.3,
    interaction: {
        mode: "point",
        intersect: true
    },
    scales: {
        x: {
            type: "timeseries",
            adapters: {
                date: {
                    locale: ptBR,
                }
            },
            time: {
                displayFormats: {
                    day: 'dd/MM',
                    month: 'MM/yyyy',
                    quarter: 'MMM yyyy',
                    year: 'yyyy',
                },
                minUnit: 'day',
                tooltipFormat: 'dd/MM/yyyy',
            },
            interaction: {
                mode: 'index'
            },
            ticks: {
                source: 'data',
            },
        },
        y: {
            title: {
                display: true,
                text: 'Reais (R$)'
            },
            ticks: {
                callback: (val, index) => val,
            },
            beginAtZero: true
        },
    },
};

//Tela com gráfico de acompanhamento do médico
export default function Grafico() {
    const [filtrando, setFiltrando] = useState(false);
    const [loading, setLoading] = useState(true);

    const [receber, setReceber] = useState(0);
    const [recebido, setRecebido] = useState(0);

    //Consts Filtro
    const [tussArray, setTussArray] = useState([]);
    const [inicialDate, setInicialDate] = useState(null);
    const inicialDateRef = useRef(null);
    const [finalDate, setFinalDate] = useState(null);
    const finalDateRef = useRef(null);
    const [filtroTUSS, setFiltroTUSS] = useState('');
    const filtroTUSSRef = useRef(null);

    //Consts do gráfico
    const chartRef = useRef(null)
    const [bar, setBar] = useState(false);
    const [data, setData] = useState({ datasets: [] });
    const [procedimentos, setProcedimentos] = useState([]);
    const menos3meses = useMemo(() => new Date(new Date().setMonth(new Date().getMonth() - 3)), []);
    const mais3meses = useMemo(() => new Date(new Date().setMonth(new Date().getMonth() + 3)), []);

    //Consts do Alerta
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const logout = () => {
        api
            .post('/logout')
            .then(() => {
                sessionStorage.clear();
                window.location.replace('/');
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        //Request do array de TUSS cadastrados
        const getTuss = api.get('/procedimento/listTuss');
        //Request dos procedimentos pro grafico
        const getGrafico = api.get("/procedimento/graph/" + JSON.parse(sessionStorage.getItem('data')).uid);
        Promise
            .all([getTuss, getGrafico])
            .then((res) => {
                //Preenche select dos TUSS
                let arr = [];
                res[0].data.forEach((tuss) => {
                    arr.push({
                        title: tuss.name,
                        code: tuss.code
                    });
                });
                setTussArray(arr);

                if (res[1].data === "Não existe procedimento com o registro desse médico") {
                    // alert(res[1].data);
                } else {
                    let tempRecebido = 0;
                    let tempReceber = 0;
                    res[1].data.forEach(pcd => {
                        if (!Array.isArray(pcd.received_value)) {
                            if (pcd.received_value > 0)
                                tempRecebido += pcd.received_value;
                            else
                                tempReceber += pcd.registered_value;
                        } else {
                            pcd.received_value.forEach((value, key) => {
                                if (value > 0)
                                    tempRecebido += value;
                                else
                                    tempReceber += pcd.registered_value[key];
                            });
                        }
                    });
                    let datasets = preencheGrafico(res[1].data, menos3meses, mais3meses);
                    setData({
                        color: ['#ff0000'],
                        datasets: datasets
                    });
                    setProcedimentos(res[1].data);
                    setReceber(tempReceber.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, "."));
                    setRecebido(tempRecebido.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, "."));
                    setFiltrando(false);
                }
                setLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data === "Unauthorized") {
                            logout();
                            return;
                        }
                        else {
                            alert(err.response.data);
                            setMessage(err.response.data);
                        }
                    }
                    else
                        alert(err.response);
                }
                else
                    alert(err);
            });
    }, [mais3meses, menos3meses]);

    const handleSubmit = (form) => {
        form.preventDefault();
        setFiltrando(true);

        if (finalDate - inicialDate < 0) {
			alert('A data inicial deve ser anterior a data final!');
            setFiltrando(false);
			return;
		}

        //Request dos valores do TUSS escolhido
        if (filtroTUSS)
            api
                .post('/procedimento/filterTuss', { ...filtroTUSS, first_date: inicialDate.valueOf(), last_date: finalDate.valueOf() })
                .then((res) => {
                    let valoresMedia = [];

                    res.data.forEach(day => {
                        let media = day.value / day.qtd;
                        valoresMedia.push({
                            x: new Date(format(new Date(parseInt(day.date)), 'yyyy-MM-dd') + 'T00:00:00.000-03:00'),
                            y: media ? media : 0
                        });
                    })
                    let data = [{
                        label: 'Média',
                        data: valoresMedia,
                        fill: false,
                        backgroundColor: 'rgb(255, 98, 78)',
                        borderColor: 'rgb(255, 98, 78)',
                    }, ...preencheGrafico(procedimentos, inicialDate, finalDate)];
                    setData({
                        color: ['#ff0000'],
                        datasets: data
                    });
                    setFiltrando(false);
                })
                .catch((err) => {
                    setFiltrando(false);
                    if (err.response) {
                        if (err.response.data) {
                            if (err.response.data === "Unauthorized") {
                                logout();
                            }
                            else if (err.response.status === 404) {
                                alert("Não foi possível filtrar pelo tuss")
                            }
                            else
                                alert(err.response.data);
                        }
                        else
                            alert(err.response);
                    }
                    else
                        alert(err);
                })
        else {
            setData({
                color: ['#ff0000'],
                datasets: preencheGrafico(procedimentos, inicialDate, finalDate)
            });
            setFiltrando(false);
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Navbar />
            <div className='graficoContainer'>
                <Collapse in={open} className="Alerta">
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={() => { setOpen(false) }}
                    >
                        {message}
                    </Alert>
                </Collapse>
                {/* Filtro */}
                <form className="graficoFiltro" onSubmit={handleSubmit}>
                    <div>
                        <KeyboardDatePicker
                            openTo="month"
                            views={["year", "month", "date"]}
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            id="inicial"
                            value={inicialDate}
                            inputRef={inicialDateRef}
                            disabled={filtrando || loading}
                            margin='dense'
                            label="Data Inicial"
                            required
                            onChange={(e) => setInicialDate(e)}
                            className="graficoDate"
                        />
                        <KeyboardDatePicker
                            openTo="month"
                            views={["year", "month", "date"]}
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            id="final"
                            disabled={filtrando || loading}
                            value={finalDate}
                            inputRef={finalDateRef}
                            label="Data final"
                            margin='dense'
                            required
                            onChange={(e) => setFinalDate(e)}
                            className="graficoDate"
                        />
                        <Autocomplete
                            id="pesquisaTuss"
                            disabled={filtrando || loading}
                            options={tussArray}
                            onChange={(event, value) => {
                                if (value) {
                                    setFiltroTUSS({
                                        code: value.code,
                                        name: value.title
                                    });
                                }
                                else {
                                    setFiltroTUSS(null);
                                }
                            }}
                            getOptionLabel={(option) => option.code + ' - ' + option.title}
                            className="graficoInput"
                            renderInput={(params) => <TextField {...params} inputRef={filtroTUSSRef} margin='dense' label="Pesquisar TUSS" variant="outlined" />}
                        />
                    </div>
                    <div>
                        <button
                            type='submit'
                            disabled={filtrando || loading}
                            className="graficoBtn"
                        >
                            {filtrando && <CircularProgress className="loadingFiltroGrafico" />}
                            {!filtrando && 'Filtrar'}
                        </button>
                        <button
                            type='button'
                            className="graficoBtnLimpar"
                            onClick={() => {
                                setData({
                                    color: ['#ff0000'],
                                    datasets: preencheGrafico(procedimentos, menos3meses, mais3meses)
                                });
                            }}
                        >
                            Limpar Filtro
                        </button>
                    </div>
                </form>

                <div>
                    {/* Gráfico */}
                    <div className='graficoDivGrafico'>
                        {!bar &&
                            <Line
                                ref={chartRef}
                                className='graficoCanva'
                                data={data}
                                options={options}
                            >
                                <p>Opa, parece que algo deu errado, por favor recarregue a pagina.</p>
                            </Line>
                        }
                        {bar &&
                            <Bar
                                ref={chartRef}
                                className='graficoCanva'
                                data={data}
                                options={options}
                            >
                                <p>Opa, parece que algo deu errado, por favor recarregue a pagina.</p>
                            </Bar>
                        }
                    </div>

                    {/* Valores recebidos e a Receber */}
                    <div className='graficoValores'>
                        <div className='graficoRecebido'>
                            <p>Quanto já recebi:</p>
                            <p>R$ {recebido}</p>
                        </div>
                        <div className='graficoDivider' />
                        <div className='graficoReceber'>
                            <p>Quanto tenho a receber:</p>
                            <p>R$ {receber}</p>
                        </div>

                        <button
                            onClick={() => setBar(!bar)}
                            className="graficoBtnTipo"
                        >
                            Gráfico de {bar ? 'Linha' : 'Barra'}
                        </button>
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )
}
