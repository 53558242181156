import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Footer from './../../components/footer/index';
import Navbar from '../../components/navbar/index';
import { Link } from "react-router-dom";
import api from '../../services/api';
import React from 'react';
import './menu.css';

//Tela de navegação com links parLink as paginas do site
export default function Menu() {
    const logout = () => {
        api
            .post('/logout')
            .then(() => {
                sessionStorage.clear();
                window.location.replace('/');
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className='menuContainer'>
            <Navbar />
            <div className='menuContent'>
                <h1 className='menuTitulo'>MENU</h1>
                <div className='menuDividerMaior'></div>
                <div className='menuUser'>
                    <AccountCircleIcon style={{ fontSize: 110 }} />
                    <div className="menuUsername">
                        <p>{JSON.parse(sessionStorage.getItem('data')).name}</p>
                    </div>
                </div>
                <div className='menuContainerLinks'>
                    <div className="menuLink">
                        <div className="menuSquare"></div>
                        <Link to='/equipe' className="menuLinkTexto" >
                            {JSON.parse(sessionStorage.getItem('data')).userTipo === "medico" ? 'Assistentes' : 'Médicos'}
                        </Link>
                    </div>

                    <div className="menuLink">
                        <div className="menuSquare"></div>
                        <Link to='/atendimentos' className="menuLinkTexto">Atendimentos</Link>
                    </div>
                    {JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico' &&
                        <div className="menuLink">
                            <div className="menuSquare"></div>
                            <Link to='/grafico' className="menuLinkTexto">Gráfico</Link>
                        </div>
                    }
                    <div className='menuDividerMenor'></div>

                    <div className="menuLink">
                        <div className="menuSquare"></div>
                        <Link to='/perfil' className="menuLinkTexto">Perfil</Link>
                    </div>

                    <div className="menuLink">
                        <div className="menuSquare"></div>
                        <Link to='/faq' className="menuLinkTexto">Perguntas frequentes</Link>
                    </div>

                    <div className="menuLink" onClick={() => logout()}>
                        <div className="menuSquare"></div>
                        <p className="menuLinkTexto" style={{margin: 0, cursor: 'pointer'}}>Desconectar</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}