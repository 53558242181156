import Politica from '../../assets/Política de Privacidade.pdf';
import React from 'react';

export default function PoliticaPrivacidade() {
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <iframe
                title="Política de Privacidade"
                className='frame'
                src={Politica}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
            />
        </div>
    )
}
