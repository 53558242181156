import React, { useEffect, useState } from 'react';
import { formataTelefone } from '../../services/utils';
import { useParams } from "react-router-dom";
import Navbar from '../../components/navbar';
import api from '../../services/api';
import { format } from 'date-fns';
import './atendimento.css';

export default function Atendimento() {
    let { id } = useParams();

    const [dados, setDados] = useState({});
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api
            .get('/procedimento/getprocedimento/' + id)
            .then((res) => {
                setDados(res.data);
                setData(res.data.date);
                setLoading(false);
            })
            .catch((err) => alert(err.response.data));
    }, [id]);

    return (
        <>
            <Navbar />
            <div className="atendimentoWrapper">
                <div className="atendimentoEsquerda">
                    <p className="atendimentoHeader">Médico</p>
                    <div className="atendimentoDivider" />
                    <p><strong>Nome: </strong>{dados.medic_name}</p>
                    <p><strong>Email: </strong>{dados.medic_email}</p>
                    <p><strong>Telefone: </strong>{dados.medic_phone ? formataTelefone(dados.medic_phone.toString()) : dados.medic_phone}</p>
                    <p><strong>CRM: </strong>{dados.medic_crm}</p>
                    <p className="atendimentoHeader">Clínica</p>
                    <div className="atendimentoDivider" />
                    <p><strong>Nome: </strong>{dados.clinic_name}</p>
                    <p><strong>Endereço: </strong>{dados.clinic_address}</p>
                    <p><strong>Telefone: </strong>{dados.clinic_phone ? formataTelefone(dados.clinic_phone.toString()) : dados.clinic_phone}</p>
                </div>
                <div className="atendimentoDireita">
                    <p className="atendimentoHeader">Paciente</p>
                    <div className="atendimentoDivider" />
                    <p><strong>Paciente: </strong>{dados.patient_name}</p>
                    <p><strong>Plano de saúde: </strong>{dados.health_plan}</p>
                    <p><strong>Data: </strong>{new Date(data).getHours() > 1 ? format(new Date(data), "dd/MM/yyyy") : format(new Date(data), "dd/MM/yyyy")}</p>
                    <p className="atendimentoHeader">Procedimentos</p>
                    <div className="atendimentoDivider" />
                    {!loading && Array.isArray(dados.tuss) &&
                        dados.tuss.map((proced, key) => {
                            return (
                                <>
                                    <p key={key + 'p1'}><strong>TUSS: </strong>{dados.tuss[key].code} - {dados.tuss[key].name}</p>
                                    <p key={key + 'p2'}><strong>Valor Cadastrado: </strong>{dados.registered_value[key]}</p>
                                    {dados.received_value[key] > 0 && <p key={key + 'p3'}><strong>Valor Recebido: </strong>{dados.received_value[key]}</p>}
                                    {key + 1 !== dados.tuss.length ? <div className="atendimentoDivider" /> : ''}
                                </>
                            )
                        })
                    }
                    {!loading && !Array.isArray(dados.tuss) &&
                        <>
                            <p><strong>TUSS: </strong>{dados.tuss.code} - {dados.tuss.name}</p>
                            <p><strong>Valor Cadastrado: </strong>{dados.registered_value}</p>
                            {dados.received_value > 0 && <p><strong>Valor Recebido: </strong>{dados.received_value}</p>}
                        </>
                    }
                </div>
            </div>
        </>
    )
}
