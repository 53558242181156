import Logo from './../../assets/logopbranca.png';
import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import './footer.css';

function footer() {
    return (
        <div className="footerContainer">
            <div className="footerLogo">
                <img src={Logo} alt="Logo CashCare"></img>
            </div>
            <div className="footerEndereco">
                <p>Endereço</p>
                <p>Brasília/DF</p>
            </div>
            <div className="footerLinks">
                <p>Links Úteis</p>
                <Link to='/planos'>Planos</Link>
                <Link to='/login'>Acesse a plataforma</Link>
                <Link to='/faq'>Perguntas Frequentes</Link>
                <Link to='/termos'>Termos de uso</Link>
                <Link to='/politica-privacidade'>Política de privacidade</Link>
            </div>
            <div className="footerApps">
                <p>Aplicativos</p>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default memo(footer);