import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import CardAssistente from '../../components/card_assistente/index';
import Footer from '../../components/footer/index';
import Navbar from '../../components/navbar/index';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import './equipe.css';

//Tela que mostra a equipe de um usuário e adiociona ou retira outro da equipe
export default function Equipe() {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [enviando, setEnviando] = useState(false);
    const [tipo] = useState(() => {
        if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
            return true
        else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
            return false
        else {
            logout();
        }
    });

    const [equipe, setEquipe] = useState([]);
    const [mensagemArray] = useState(tipo ? "Você não tem nenhum assistente na sua equipe" : "Você não está vinculado a nenhum médico");

    //Modal de cadastro
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const emailRef = useRef(null);

    const logout = useCallback(() => {
        api
            .post('/logout')
            .then(() => {
                sessionStorage.clear();
                history.replace('/');
            })
            .catch((err) => console.log(err));
    }, [history]);

    useEffect(() => {
        let rota = tipo ? '/medico/assistente/' : '/assistente/medics/';

        api
            .get(rota + JSON.parse(sessionStorage.getItem('data')).uid)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name))
                setEquipe(res.data);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data === "Unauthorized") {
                            logout();
                        }
                        else
                            alert(err.response.data);
                    }
                    else
                        alert(err.response);
                }
                else
                    alert(err);
            })
    }, [tipo, logout]);

    const handleAdicionar = (form) => {
        form.preventDefault();
        setEnviando(true);
        let json = '';
        if (!tipo)
            json = {
                medic: email,
                assistent: JSON.parse(sessionStorage.getItem('data')).uid,
                type: JSON.parse(sessionStorage.getItem('data')).userTipo
            };
        else if (tipo)
            json = {
                medic: JSON.parse(sessionStorage.getItem('data')).uid,
                assistent: email,
                type: JSON.parse(sessionStorage.getItem('data')).userTipo
            };

        api
            .put('/createLink/email', json)
            .then((res) => {
                setEmail('');
                setOpen(false);
                setEnviando(false);
                if (!tipo) {
                    alert("Pedido enviado com sucesso! Assim que o médico aprova-lo você entrará na equipe dele!");
                    return;
                }
                let temp = [...equipe, res.data];
                temp.sort((a, b) => a.name.localeCompare(b.name));
                setEquipe(temp);
            })
            .catch((err) => {
                setEnviando(false);
                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data === "Unauthorized") {
                            logout();
                        }
                        else
                            alert(err.response.data);
                    }
                    else
                        alert(err.response);
                }
                else
                    alert(err);
            })
    }

    const removeCard = (deletado) => {
        let temp = equipe.filter(({ id }) => id !== deletado);
        setEquipe(temp);
    };

    const stateUpdater = (uid) => {
        let newEquipe = equipe.map(member => {
            if (member.id === uid) {
                member.state = true;
            }
            return member
        })
        setEquipe(newEquipe);
    }

    return (
        <div className="EquipeContainer">
            <Navbar />

            {/* div adicionar assistente */}
            <section className="add-equipe">
                <Button
                    className="botao-adicionar-assistente"
                    ariant="outlined"
                    color="primary"
                    onClick={() => setOpen(true)}>
                    ADICIONAR
                </Button>
            </section>

            <section className="EquipeCards">
                {loading &&
                    <CircularProgress className="equipeLoader" thickness={4.5} />
                }
                {!loading && equipe.length === 0 &&
                    <p className="equipeArrayVazio">{mensagemArray}</p>
                }
                {!loading && equipe.length > 0 &&
                    equipe.map((membro, index) => {
                        return <CardAssistente
                            key={index}
                            uid={membro.id}
                            nome={membro.name}
                            email={membro.email}
                            telefone={membro.phone}
                            state={membro.state}
                            especialidade={membro.esp}
                            stateUpdater={stateUpdater}
                            removedor={removeCard}
                        />
                    })
                }
            </section>

            <Footer />

            {/* Modal adicionar a equipe */}
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
                <form onSubmit={handleAdicionar}>
                    <DialogTitle id="form-dialog-title">Adicionar profissional</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Para adicionar um(a) profissional, insira o email aqui.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="email"
                            inputRef={emailRef}
                            variant="outlined"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        {!enviando &&
                            <Button onClick={() => setOpen(false)} color="primary">
                                Cancelar
                            </Button>
                        }
                        <Button type="submit" disabled={enviando} color="primary" style={enviando ? { width: 150 } : {}}>
                            {enviando && <CircularProgress className="loadingEquipe" />}
                            {!enviando && 'Adicionar'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}