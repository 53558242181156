import { TextField, MenuItem, Select, FormControl, InputLabel, Collapse, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete, createFilterOptions, Alert } from '@material-ui/lab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useState, useEffect, useRef } from 'react';
import { TelefoneCustom } from '../../services/mascaras';
import { verificaIdade } from '../../services/utils';
import Navbar from '../../components/navbar/index';
import { useHistory } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import api from '../../services/api';
import firebase from "firebase/app";
import { format } from 'date-fns';
import CryptoJS from "crypto-js";
import './editar_perfil.css';
import "firebase/auth";
import 'date-fns';

//Parte do Autocomplete
const filter = createFilterOptions();

//Tela de Edição de informações do usuário
export default function EditarPerfil() {
    const history = useHistory();
    const [enviando, setEnviando] = useState(false);
    const [tipo] = useState(() => {
        if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
            return true
        else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
            return false
        else {
            api
                .post('/logout')
                .then(() => {
                    sessionStorage.clear();
                    window.location.replace('/');
                })
                .catch((err) => console.log(err));
        }
    });

    //Array de especialidades
    const [especialidade, setEspecialidade] = useState([]);

    //Consts do usuário
    const [email, setEmail] = useState('');
    const [emailAntigo, setEmailAntigo] = useState('');
    const emailRef = useRef(null);
    const [niver, setNiver] = useState(null);
    const niverRef = useRef(null);
    const [nome, setNome] = useState('');
    const nomeRef = useRef(null);
    const [tel, setTel] = useState('');
    const telRef = useRef(null);
    const [esp, setEsp] = useState('');
    const espRef = useRef(null);
    const [UF, setUF] = useState('');
    const UFRef = useRef(null);

    const [senhaConf, setSenhaConf] = useState('');
    const senhaConfRef = useRef(null);
    const [senha, setSenha] = useState('');
    const senhaRef = useRef(null);
    const [trocarSenha, setTrocarSenha] = useState(false);

    //Consts do alerta
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (tipo) {
            api.get('/specialty').then(response => {
                let espUnicas = [...new Set(response.data)];
                espUnicas = espUnicas.map((esp) => {
                    return {
                        title: esp,
                        value: esp
                    }
                });
                setEspecialidade(espUnicas);
            })
            setEsp(JSON.parse(sessionStorage.getItem('data')).specialty)
            setUF(JSON.parse(sessionStorage.getItem('data')).uf)
        }
        setEmail(JSON.parse(sessionStorage.getItem('data')).email);
        setEmailAntigo(JSON.parse(sessionStorage.getItem('data')).email);
        setNiver(new Date(JSON.parse(sessionStorage.getItem('data')).birth));
        setNome(JSON.parse(sessionStorage.getItem('data')).name);
        setTel(JSON.parse(sessionStorage.getItem('data')).phone);
    }, [tipo])

    const handleEditar = (form) => {
        form.preventDefault();
        if (senha !== senhaConf) {
            setMessage("As senhas digitadas são diferentes");
            setSenhaConf('')
            setOpen(true);
            senhaConfRef.current.focus();
        }
        else if (senha && (senha.length < 8 || senha.length > 20)) {
            setMessage("A senha digitada tem que ter no minimo 8 e no máximo 20 caracteres");
            setSenha('');
            setSenhaConf('');
            setOpen(true);
            senhaRef.current.focus();
        }
        else if (tel.length < 10) {
            setMessage("O telefone digitado é invalido");
            setOpen(true);
            telRef.current.focus();
        }
        else if (nome.indexOf(' ') < 0) {
            setMessage("Por favor digite seu nome e sobrenome");
            setOpen(true);
            nomeRef.current.focus();
        }
        else if (verificaIdade(niver) < 18) {
            setMessage("Apenas maiores de 18 anos podem se cadastrar");
            setNiver(null);
            setOpen(true);
            niverRef.current.focus();
        }
        else {
            setEnviando(true);
            const user = firebase.auth().currentUser;
            let niverFormatado = format(niver, 'yyyy-MM-dd');
            let rota = tipo ? '/medico/update/' : '/assistente/update/';
            let passNova = CryptoJS.SHA1(senha);
            let json;

            //Padronização da especialidade
            const formataEspecialidade = (string) => {
                string = string.trim().toLowerCase();
                return string.charAt(0).toUpperCase() + string.slice(1);
            }

            if (tipo) {
                json = {
                    "name": nome,
                    "email": email,
                    "phone": parseInt(tel),
                    "specialty": esp.title ? formataEspecialidade(esp.title) : esp.title,
                    "uf": UF,
                    "birth": niverFormatado,
                };
            }
            else {
                json = {
                    "name": nome,
                    "email": email,
                    "phone": parseInt(tel),
                    "birth": niverFormatado,
                };
            };
            let promises = [];
            if (senha) {
                let password = passNova.toString();
                const passReq = user.updatePassword(password);
                promises.push(passReq);
            }
            if (emailAntigo !== email) {
                const emailReq = user.updateEmail(email)
                promises.push(emailReq);
            }
            Promise
                .all(promises)
                .then(res => {
                    user
                        .getIdToken(true)
                        .then((idToken) => {
                            //Atualização das infos no backend
                            api
                                .patch(rota + JSON.parse(sessionStorage.getItem('data')).uid, json, {
                                    headers: {
                                        authtoken: idToken
                                    }
                                })
                                .then(() => {
                                    let novoJson = {
                                        email: email,
                                        uid: JSON.parse(sessionStorage.getItem('data')).uid,
                                        userTipo: JSON.parse(sessionStorage.getItem('data')).userTipo,
                                        name: nome,
                                        phone: tel,
                                        birth: niverFormatado,
                                        cpf: JSON.parse(sessionStorage.getItem('data')).cpf,
                                    };

                                    if (tipo) {
                                        novoJson.crm = JSON.parse(sessionStorage.getItem('data')).crm;
                                        novoJson.uf = UF;
                                        novoJson.specialty = esp.title ? formataEspecialidade(esp.title) : JSON.parse(sessionStorage.getItem('data')).specialty;
                                    }

                                    sessionStorage.clear();
                                    sessionStorage.setItem('data', JSON.stringify(novoJson));
                                    setEnviando(false);
                                    history.push(`/perfil`);
                                })
                                .catch((err) => {
                                    setEnviando(false);
                                    if (err.response)
                                        if (err.response.data) {
                                            if (err.response.data === "Unauthorized") {
                                                api
                                                    .post('/logout')
                                                    .then(() => {
                                                        sessionStorage.clear();
                                                        window.location.replace('/');
                                                    })
                                                    .catch((err) => console.log(err));
                                            }
                                            else
                                                alert(err.response.data);
                                        }
                                        else
                                            alert(err.response);
                                    else
                                        alert(err);
                                })
                        });
                })
                .catch(error => {
                    setEnviando(false);
                    if (error === 'The email address is already in use by another account.') {
                        setMessage("Este email já está sendo usado por outro usuário");
                        setOpen(true);
                        setEmail('');
                        emailRef.current.focus();
                    }
                    else if (error.message === 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.') {
                        alert('Por favor faça login novamente para poder editar seu perfil!');
                        sessionStorage.setItem('reautenticacao', 'true');
                        history.push('/login?tipo=' + JSON.parse(sessionStorage.getItem('data')).userTipo);
                    }
                    else
                        console.log(error);
                });

            if (promises.length === 0) {
                //Atualização das infos no backend
                api
                    .patch(rota + JSON.parse(sessionStorage.getItem('data')).uid, json)
                    .then(() => {
                        let novoJson = {
                            email: email,
                            uid: JSON.parse(sessionStorage.getItem('data')).uid,
                            userTipo: JSON.parse(sessionStorage.getItem('data')).userTipo,
                            name: nome,
                            phone: tel,
                            birth: niverFormatado,
                            cpf: JSON.parse(sessionStorage.getItem('data')).cpf,
                        };

                        if (tipo) {
                            novoJson.crm = JSON.parse(sessionStorage.getItem('data')).crm;
                            novoJson.uf = UF;
                            novoJson.specialty = esp.title ? formataEspecialidade(esp.title) : JSON.parse(sessionStorage.getItem('data')).specialty;
                        }

                        sessionStorage.clear();
                        sessionStorage.setItem('data', JSON.stringify(novoJson));
                        setEnviando(false);
                        history.push(`/perfil`);
                    })
                    .catch((err) => {
                        setEnviando(false);
                        if (err.response)
                            if (err.response.data) {
                                if (err.response.data === "Unauthorized") {
                                    api
                                        .post('/logout')
                                        .then(() => {
                                            sessionStorage.clear();
                                            window.location.replace('/');
                                        })
                                        .catch((err) => console.log(err));
                                }
                                else
                                    alert(err.response.data);
                            }
                            else
                                alert(err.response);
                        else
                            alert(err);
                    })
            }
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <Collapse in={open} className="Alerta">
                <Alert
                    variant="filled"
                    severity="error"
                    onClose={() => { setOpen(false) }}
                >
                    {message}
                </Alert>
            </Collapse>

            <Navbar />

            <div className="perfilContainer">
                <p className="perfilTitulo">Perfil</p>

                <div className="perfilDivider" />

                <form className="perfilInfo" onSubmit={handleEditar}>
                    <div>
                        <AccountCircleIcon className="perfilUserIcon" />
                        <div className="perfilDados" >
                            <TextField
                                autoFocus
                                id="nome"
                                type="name"
                                variant="outlined"
                                label="Nome Completo"
                                required
                                inputRef={nomeRef}
                                value={nome}
                                onChange={(n) => setNome(n.target.value)}
                                className="perfilInput"
                            />
                            <TextField
                                id="email"
                                type="email"
                                autoComplete="email"
                                variant="outlined"
                                label="Email"
                                required
                                inputRef={emailRef}
                                value={email}
                                onChange={(n) => setEmail(n.target.value)}
                                className="perfilInput"
                            />
                            <TextField
                                id="tel"
                                label="Telefone"
                                name="tel"
                                variant="outlined"
                                inputRef={telRef}
                                value={tel}
                                onChange={(n) => setTel(n.target.value)}
                                InputProps={{
                                    inputComponent: TelefoneCustom,
                                }}
                                required
                                className="perfilInput"
                            />
                            {tipo &&
                                <Autocomplete
                                    id="esp"
                                    value={esp}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setEsp({
                                                title: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setEsp({
                                                title: newValue.inputValue,
                                            });
                                        } else {
                                            setEsp(newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                title: `Add "${params.inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={especialidade}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.title;
                                    }}
                                    renderOption={(option) => option.title}
                                    freeSolo
                                    className="esp perfilInput"
                                    renderInput={(params) => (
                                        <TextField {...params} required inputRef={espRef}
                                            label="Especialidade" className="perfilInput" variant="outlined" />
                                    )}
                                />
                            }
                        </div>
                    </div>
                    <div>
                        <p>Informarções Privadas</p>
                        <div className="perfilInfoDivider" />
                        <div className="perfilDadosD">
                            <KeyboardDatePicker
                                id="niver"
                                value={niver}
                                inputRef={niverRef}
                                onChange={(n) => setNiver(n)}
                                openTo="year"
                                views={["year", "month", "date"]}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                label="Data de aniversário"
                                required
                                disableFuture='true'
                                className="perfilNiver perfilInput"
                            />
                            {tipo &&
                                <FormControl variant="outlined" className="perfilUF perfilInput">
                                    <InputLabel id="UF-label">UF</InputLabel>
                                    <Select
                                        id="UF"
                                        value={UF}
                                        inputRef={UFRef}
                                        onChange={(n) => setUF(n.target.value)}
                                        required
                                        labelId="UF-label"
                                        label="UF"
                                    >
                                        <MenuItem value={"AC"}>AC</MenuItem>
                                        <MenuItem value={"AL"}>AL</MenuItem>
                                        <MenuItem value={"AP"}>AP</MenuItem>
                                        <MenuItem value={"AM"}>AM</MenuItem>
                                        <MenuItem value={"BA"}>BA</MenuItem>
                                        <MenuItem value={"CE"}>CE</MenuItem>
                                        <MenuItem value={"DF"}>DF</MenuItem>
                                        <MenuItem value={"ES"}>ES</MenuItem>
                                        <MenuItem value={"GO"}>GO</MenuItem>
                                        <MenuItem value={"MA"}>MA</MenuItem>
                                        <MenuItem value={"MT"}>MT</MenuItem>
                                        <MenuItem value={"MS"}>MS</MenuItem>
                                        <MenuItem value={"MG"}>MG</MenuItem>
                                        <MenuItem value={"PA"}>PA</MenuItem>
                                        <MenuItem value={"PB"}>PB</MenuItem>
                                        <MenuItem value={"PR"}>PR</MenuItem>
                                        <MenuItem value={"PE"}>PE</MenuItem>
                                        <MenuItem value={"PI"}>PI</MenuItem>
                                        <MenuItem value={"RR"}>RR</MenuItem>
                                        <MenuItem value={"RO"}>RO</MenuItem>
                                        <MenuItem value={"RJ"}>RJ</MenuItem>
                                        <MenuItem value={"RN"}>RN</MenuItem>
                                        <MenuItem value={"RS"}>RS</MenuItem>
                                        <MenuItem value={"SC"}>SC</MenuItem>
                                        <MenuItem value={"SP"}>SP</MenuItem>
                                        <MenuItem value={"SE"}>SE</MenuItem>
                                        <MenuItem value={"TO"}>TO</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </div>
                        <div className="perfilSenhas">
                            {!trocarSenha &&
                                <button type="button" className="perfilEditarbtn" onClick={() => setTrocarSenha(true)}>Trocar Senha</button>
                            }
                            {trocarSenha &&
                                <div div className="perfilSenhasAnt">
                                    <TextField
                                        id="senha"
                                        value={senha}
                                        inputRef={senhaRef}
                                        onChange={(n) => setSenha(n.target.value)}
                                        label="Nova senha"
                                        autoComplete="new-password"
                                        name="senha"
                                        type="password"
                                        variant="outlined"
                                        className="perfilInput editarSenhaEsquerda"
                                    />
                                    <TextField
                                        id="senha_conf"
                                        value={senhaConf}
                                        inputRef={senhaConfRef}
                                        onChange={(n) => setSenhaConf(n.target.value)}
                                        label="Confirme sua nova senha"
                                        autoComplete="new-password"
                                        name="senha_conf"
                                        type="password"
                                        variant="outlined"
                                        className="perfilInput"
                                    />
                                </div>
                            }
                            <button type="submit" className="perfilEditarbtn" disabled={enviando} style={enviando ? { width: 81, paddingTop: 5 } : {}}>
                                {enviando && <CircularProgress className="loadingEditarPerfil" />}
                                {!enviando && 'Salvar'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </MuiPickersUtilsProvider >
    );
}