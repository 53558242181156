import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import Navbar from '../../components/navbar/index';
import api from '../../services/api';
import { formataTelefone } from '../../services/utils';
import './perfil.css';

//Tela de perfil do usuário
export default function Perfil() {
    const history = useHistory();
    const user = JSON.parse(sessionStorage.getItem('data'));
    const [tipo] = useState(() => {
        if (user.userTipo === 'medico')
            return true
        else if (user.userTipo === 'assistente')
            return false
        else {
            api
                .post('/logout')
                .then(() => {
                    sessionStorage.clear();
                    history.replace('/');
                })
                .catch((err) => console.log(err));
        }
    });

    const handleDeleteAccount = () => {
        const confirm = window.confirm('Você tem certeza que quer apagar sua conta? Todos os seus dados serão excluídos e esta ação é irreversível.');
        if (confirm)
            api
                .delete("/" + user.userTipo + "/" + user.uid)
                .then(() => {
                    alert("Seus dados serão excluídos de nossos servidores!");
                    api.post('/logout')
                        .then(() => {
                            sessionStorage.clear();
                            history.replace('/');
                        })
                        .catch((err) => console.log(err));
                })
                .catch((error) => {
                    alert("Não foi possível apagar sua conta.");
                    console.log(error.response);
                })
    }

    const cpf = user.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    return (
        <>
            <Navbar />

            <div className="perfilContainer">
                <p className="perfilTitulo">Perfil</p>

                <div className="perfilDivider" />

                <div className="perfilInfo">
                    <div>
                        <AccountCircleIcon className="perfilUserIcon" />
                        <div className="perfilDados" >
                            <p className="perfilUserInfo">Nome: {user.name}</p>
                            <p className="perfilUserInfo">Email: {user.email}</p>
                            <p className="perfilUserInfo">Telefone: {formataTelefone(user.phone.toString())}</p>
                            {tipo &&
                                <p className="perfilUserInfo">Especialidade: {user.specialty}</p>
                            }
                        </div>
                    </div>
                    <div>
                        <p>Informarções Privadas</p>
                        <div className="perfilInfoDivider" />
                        <div className="perfilDadosD">
                            <p className="perfilUserInfo">CPF: {cpf}</p>
                            {tipo &&
                                <p className="perfilUserInfo">CRM: {user.crm}</p>
                            }
                            {tipo &&
                                <p className="perfilUserInfo">UF: {user.uf}</p>
                            }
                            <p className="perfilUserInfo">Data de Nascimento: {format(new Date(user.birth), 'dd/MM/yyyy')}</p>
                            <div className='perfildivBtn'>
                                <Link to='/editar_perfil' className="perfilEditarLinkbtn">
                                    Editar Perfil
                                </Link>
                                <button onClick={handleDeleteAccount} className="deleteAccountbtn">
                                    Apagar Conta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}