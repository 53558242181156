import { Button, TextField, MenuItem, Select, FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, Collapse, Slide, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback, useRef } from "react";
import CardClinica from '../../components/card_clinica/index';
import { TelefoneCustom } from '../../services/mascaras';
import { formataTelefone } from '../../services/utils';
import Footer from '../../components/footer/index';
import Navbar from '../../components/navbar/index';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import api from '../../services/api';
import PropTypes from "prop-types";
import './clinicas.css';

//Definição das props da mascara
TelefoneCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

//Animação do modal
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Clinicas() {
    const [enviando, setEnviando] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [tipo] = useState(() => {
        if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
            return true;
        else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
            return false;
        else {
            logout();
        }
    });

    //Arrays de medicos e clinicas
    const [clinicas, setClinicas] = useState([]);
    const [medicos, setMedicos] = useState([]);
    const [mensagemArray, setMensagemArray] = useState('');

    //Consts pra cadastro de clinica
    const [id, setId] = useState('');
    const [medico, setMedico] = useState('');
    const medicoRef = useRef(null);
    const [nome, setNome] = useState('');
    const nomeRef = useRef(null);
    const [nomeAnt, setNomeAnt] = useState('');
    const [endereco, setEndereco] = useState('');
    const endRef = useRef(null);
    const [telefone, setTelefone] = useState('');
    const telRef = useRef(null);
    const [email, setEmail] = useState('');
    const emailRef = useRef(null);

    //Modal de cadastro
    const [open, setOpen] = useState(false);
    const [tituloModal, setTituloModal] = useState('Adicionar clínica');
    const [textoBtnModal, setTextoBtnModal] = useState('ADICIONAR');

    //Modal de Edição de clinica
    const [openEdicao, setOpenEdicao] = useState(false);

    //Mensagem do alerta e estado do alerta
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");

    //Mensagem do alerta e estado do alerta do form
    const [openAlertForm, setOpenAlertForm] = useState(false);
    const [messageForm, setMessageForm] = useState("");

    const logout = useCallback(() => {
        api
            .post('/logout')
            .then(() => {
                sessionStorage.clear();
                history.replace('/');
            })
            .catch((err) => console.log(err));
    }, [history]);

    //Carrega clinicas cadastradas do banco de dados
    useEffect(() => {
        let rota = tipo ? '/clinica/medico/' : '/assistente/clinicasMedicos/';
        api
            .get(rota + JSON.parse(sessionStorage.getItem('data')).uid)
            .then((res) => {
                let temp = [];
                let tempMed = [];
                if (tipo) {
                    if (res.data === "Não existem Clinicas com o registro desse médico") {
                        setMensagemArray('Você não possui clínicas cadastradas');
                    }
                    else
                        res.data.forEach((clinica) => {
                            temp.push({
                                id: clinica.uid,
                                medico: JSON.parse(sessionStorage.getItem('data')).uid,
                                name: clinica.name,
                                address: clinica.address,
                                email: clinica.email,
                                phone: clinica.phone,
                            });
                        });
                }
                else {
                    if (res.data.length === 0) {
                        setMensagemArray('Você não está vinculado a nenhum médico');
                    }
                    else {
                        res.data.forEach((medico) => {
                            tempMed.push({
                                id: medico.medico_id,
                                nome: medico.medico_name
                            });

                            medico.clinica_list.forEach((clinica) => {
                                temp.push({
                                    id: clinica.uid,
                                    medico: medico.medico_id,
                                    medicoNome: medico.medico_name,
                                    name: clinica.name,
                                    address: clinica.address,
                                    email: clinica.email,
                                    phone: clinica.phone,
                                });
                            });
                        });
                        if (temp.length === 0)
                            setMensagemArray('Nenhuma clínica cadastrada');
                    }
                }
                setClinicas(temp);
                setMedicos(tempMed);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data === "Unauthorized") {
                            logout();
                        }
                        else
                            alert(err.response.data);
                    }
                    else
                        alert(err.response);
                }
                else
                    alert(err);
            })
    }, [tipo, logout]);

    const handleAdicionar = (form) => {
        form.preventDefault();
        let nomeExistente = clinicas.find((clinica) => clinica.name.toLowerCase().trim() === nome.toLowerCase().trim())
        if (nomeExistente && !id) {
            setMessageForm("Uma clínica com esse nome já está cadastrada");
            setOpenAlertForm(true);
            setNome('');
            nomeRef.current.focus();
        }
        else if (nomeExistente && id && nomeAnt !== nome) {
            setMessageForm("Uma clínica com esse nome já está cadastrada");
            setOpenAlertForm(true);
            setNome('');
            nomeRef.current.focus();
        }
        else if (telefone && telefone.length < 10) {
            setMessageForm("Número digitado invalido");
            setOpenAlertForm(true);
            setTelefone('');
            telRef.current.focus();
        }
        else {
            setEnviando(true);
            let json = {
                address: endereco.trim(),
                name: nome.trim(),
                medics: !tipo ? [medico] : [JSON.parse(sessionStorage.getItem('data')).uid],
                phone: telefone ? parseInt(telefone) : undefined,
            };
            if (id) {
                api
                    .patch('/clinica/' + id, { ...json, email: email ? email : "" })
                    .then((res) => {
                        let temp = [...clinicas];
                        temp.forEach((clinica, key) => {
                            if (clinica.id === id) {
                                temp[key].address = json.address;
                                temp[key].name = json.name;
                                temp[key].medics = json.medics;
                                temp[key].phone = json.phone;
                            };
                        });
                        temp.sort((a, b) => a.name.localeCompare(b.name))
                        setClinicas(temp);
                        setEnviando(false);
                        setOpen(false);

                    })
                    .catch((err) => {
                        setEnviando(false);
                        if (err.response) {
                            if (err.response.data) {
                                if (err.response.data === "Unauthorized") {
                                    logout();
                                }
                                else
                                    alert(err.response.data);
                            }
                            else
                                alert(err.response);
                        }
                        else
                            alert(err);
                    })
            }
            else {
                api
                    .post('/clinica', { ...json, email: email ? email : "" })
                    .then((res) => {
                        let temp = [...clinicas, {
                            ...json,
                            id: res.id
                        }];
                        temp.sort((a, b) => a.name.localeCompare(b.name))
                        setClinicas(temp);
                        setEnviando(false);
                        setOpen(false);
                    })
                    .catch((err) => {
                        setEnviando(false);
                        if (err.response) {
                            if (err.response.data) {
                                if (err.response.data === "Unauthorized") {
                                    logout();
                                }
                                else
                                    alert(err.response.data);
                            }
                            else
                                alert(err.response);
                        }
                        else
                            alert(err);
                    })
            }
        }
    };

    //Limpa os inputs ao fechar modal
    const handleCancelar = () => {
        setOpenEdicao(false);
        setOpen(false);
        setTituloModal('Adicionar clínica');
        setTextoBtnModal('ADICIONAR');
        setId(null);
        setNome('');
        setEmail('');
        setEndereco('');
        setTelefone('');
        setMedico(null);
    }

    const removeCard = (deletado) => {
        let temp = clinicas.filter(({ id }) => id !== deletado);
        setClinicas(temp);
    };

    //Verifica se usuário tem médicos cadastrados pra abrir o modal de cadastro
    const OpenModal = () => {
        if (!tipo)
            if (medicos.length > 0)
                setOpen(true);
            else {
                setMessage("Opa, parece que você não está vinculado a nenhum médico");
                setOpenAlert(true);
            }
        else
            setOpen(true);
    }

    return (
        <div className="clinicasContainer">
            <Collapse in={openAlert} className="Alerta">
                <Alert
                    variant="filled"
                    severity="error"
                    onClose={() => { setOpenAlert(false) }}
                >
                    {message}
                </Alert>
            </Collapse>

            <Navbar />

            <section className="clinicaAddBtnDiv">
                <Button className="clinicaAddBtn" variant="outlined" color="primary" onClick={OpenModal}>
                    ADICIONAR
                </Button>
            </section>

            <section className="clinicasCards">
                {loading &&
                    <CircularProgress className="clinicaLoader" thickness={5} />
                }
                {!loading && clinicas.map((clinica, index) => {
                    return (
                        <CardClinica
                            customClickEvent={() => {
                                setId(clinica.id);
                                setTituloModal('Editar Clinica');
                                setTextoBtnModal('SALVAR');
                                setNome(clinica.name);
                                setNomeAnt(clinica.name);
                                setEmail(clinica.email);
                                setEndereco(clinica.address);
                                setTelefone(clinica.phone ? clinica.phone.toString() : clinica.phone);
                                setMedico(clinica.medico);
                                setOpenEdicao(true);
                            }}
                            key={index}
                            id={clinica.id}
                            nome={clinica.name}
                            nomeMedico={clinica.medicoNome}
                            endereco={clinica.address}
                            telefone={clinica.phone ? formataTelefone(clinica.phone.toString()) : clinica.phone}
                            email={clinica.email}
                            medico={clinica.medico}
                            removedor={removeCard}
                        />
                    )
                })
                }
                {!loading && clinicas.length === 0 &&
                    <p className="clinicasArrayVazio">{mensagemArray}</p>
                }
            </section>

            <Footer />

            {/* modal confirmação de edição de clinica */}
            <Dialog
                open={openEdicao}
                onClose={() => setOpenEdicao(false)}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{''}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você quer editar esta clínica?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelar} color="primary">
                        Não
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenEdicao(false)
                            setOpen(true)
                        }}
                        color="primary"
                        autoFocus
                    >
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de cadastro e edição de clinica */}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                disableBackdropClick
                aria-labelledby="form-dialog-title"
            >
                <Collapse in={openAlertForm} className="Alerta">
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={() => { setOpenAlertForm(false) }}
                    >
                        {messageForm}
                    </Alert>
                </Collapse>
                <form onSubmit={handleAdicionar}>
                    <DialogTitle id="form-dialog-title">{tituloModal}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Insira os dados da clínica abaixo:
                        </DialogContentText>
                        {!tipo &&
                            <FormControl variant="outlined" required fullWidth margin="dense" className="inputCadastro UF">
                                <InputLabel id="medico-label">Médico</InputLabel>
                                <Select
                                    id="medico"
                                    value={medico}
                                    inputRef={medicoRef}
                                    onChange={(n) => setMedico(n.target.value)}
                                    required
                                    fullWidth
                                    margin="dense"
                                    labelId="medico-label"
                                    label="Médico"
                                >
                                    {medicos.map((med, index) =>
                                        <MenuItem
                                            key={index}
                                            value={med.id}
                                        >
                                            {med.nome}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        }
                        <TextField
                            autoFocus
                            required
                            value={nome}
                            inputRef={nomeRef}
                            onChange={(n) => setNome(n.target.value)}
                            margin="dense"
                            id="name"
                            label="Nome"
                            type="text"
                            variant='outlined'
                            fullWidth
                        />
                        <TextField
                            required
                            value={endereco}
                            inputRef={endRef}
                            onChange={(n) => setEndereco(n.target.value)}
                            margin="dense"
                            id="end"
                            label="Endereço"
                            type="text"
                            variant='outlined'
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            value={telefone}
                            inputRef={telRef}
                            onChange={(n) => setTelefone(n.target.value)}
                            id="tel"
                            label="Telefone"
                            type="text"
                            name="telefone"
                            variant='outlined'
                            fullWidth
                            InputProps={{
                                inputComponent: TelefoneCustom,
                            }}
                        />
                        <TextField
                            margin="dense"
                            value={email}
                            inputRef={emailRef}
                            onChange={(n) => setEmail(n.target.value)}
                            id="email"
                            label="Email"
                            type="email"
                            variant='outlined'
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        {!enviando &&
                            <Button onClick={handleCancelar} color="primary">
                                Cancelar
                            </Button>
                        }
                        <Button type="submit" disabled={enviando} color="primary" style={enviando ? { width: 150 } : {}}>
                            {enviando && <CircularProgress className="loadingClinicas" />}
                            {!enviando && textoBtnModal}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    );
}