import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import React from 'react';

import CadastroProcedimentos from '../../pages/cadastro_procedimentos/index';
import PoliticaPrivacidade from '../../pages/politica-privacidade/index';
import EmailHandler from '../../pages/email_handler/index';
import Procedimentos from '../../pages/procedimentos/index';
import EditarPerfil from '../../pages/editar_perfil/index';
import Atendimento from '../../pages/atendimento/index';
import Tipo_user from '../../pages/tipo_usuario/index';
import Cadastro from '../../pages/cadastro/index';
import Clinicas from '../../pages/clinicas/index';
import Grafico from '../../pages/grafico/index';
import Equipe from '../../pages/equipe/index';
import Planos from '../../pages/planos/index';
import Perfil from '../../pages/perfil/index';
import Termos from '../../pages/termos/index';
import Login from '../../pages/login/index';
import Menu from '../../pages/menu/index';
import Home from '../../pages/home/index';
import Faq from '../../pages/faq/index';

const Rotas = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/escolhe_usuario" exact component={Tipo_user} />
        <Route path="/login" component={Login} />
        <Route path="/cadastro" component={Cadastro} />
        <Route path="/planos" component={Planos} />
        <Route path="/termos" component={Termos} />
        <Route path="/faq" component={Faq} />
        <Route path="/email_handler" component={EmailHandler} />
        <Route path="/politica-privacidade" component={PoliticaPrivacidade} />
        <PrivateRoute path="/atendimentos" component={Procedimentos} />
        <PrivateRoute path="/menu" component={Menu} />
        <PrivateRoute path="/equipe" component={Equipe} />
        <PrivateRoute path="/cadastro_atendimento" component={CadastroProcedimentos} />
        <PrivateRoute path="/atendimento/:id" component={Atendimento} />
        <PrivateRoute path="/perfil" component={Perfil} />
        <PrivateRoute path="/editar_perfil" component={EditarPerfil} />
        <PrivateRoute path="/grafico" component={Grafico} />
        <PrivateRoute path="/clinicas" component={Clinicas} />
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
    </Router>
  );
}

export default Rotas;