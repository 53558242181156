import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavbarDeslogado from './../../components/navbar2';
import NavbarLogado from './../../components/navbar';
import Footer from '../../components/footer/index';
import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import './faq.css';

const cookies = new Cookies();

class Faq extends Component {

    //Sobe a pagina depois que carrega
    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    expandeResposta = (id) => {
        $(id.currentTarget).children('#iconDiv').children('#icon').toggleClass('openIcon');
        $(id.currentTarget).children('#idRes').toggleClass('openRes');
    };

    render() {
        return (
            <div className="FAQ">
                {cookies.get('controller') ?
                    <NavbarLogado />
                    :
                    <NavbarDeslogado />
                }

                <div className="divFaq">
                    <div className="tituloFaq">
                        <p>FAQ</p>
                        <p>Perguntas</p>
                        <p>Frequentes</p>
                    </div>

                    <div className="dividerFaq" />

                    <div className="perguntasFaq">
                        <div className="perguntaFaq" onClick={(id) => this.expandeResposta(id)}>
                            <div id="iconDiv">
                                <p>Preciso enviar um arquivo no cadastro de um atendimento?</p>
                                <ExpandMoreIcon className="abrirIcon" id="icon" />
                            </div>
                            <div className="bottomLineFaq"></div>
                            <div className="respostaPergunta" id='idRes'>
                                Não, você não precisa. O envio de um arquivo é opcional, porém, caso decida enviá-lo, poderá encontrá-lo na caixa de entrada do e-mail do médico que realizou o atendimento.
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
export default Faq;