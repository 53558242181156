import Logo from "./../../assets/logopbranca.png"
import React, { useState, memo } from 'react';
import { MenuItens } from "./menuitens";
import { Link } from 'react-router-dom';
import './navbar.css';

function Navbar() {
    const [clicked, setClicked] = useState(false);

    return (
        <nav className="NavbarItens">
            <Link className="navbar-logo" to='/'><img className="navbar-logo" src={Logo} alt="Logo CashCare"></img></Link>
            <div className="menu-icon" onClick={() => setClicked(!clicked)}>
                <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}> </i>
            </div>
            <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                {MenuItens.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.cName} to={item.url} >
                                {item.title}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default memo(Navbar);