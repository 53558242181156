import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress, Collapse, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import 'date-fns';
import { format } from 'date-fns';
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { ValorCustom } from '../../services/mascaras';
import Navbar from "./../../components/navbar/index";
import './cadastro_procedimentos.css';

//Definição das props das mascaras
ValorCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

//Parte do Autocomplete
const filter = createFilterOptions();

//Tela de Cadastro de Procedimentos
function CadastroProcedimento() {
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [enviando, setEnviando] = useState(false);
  const [tipo] = useState(() => {
    if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
      return true
    else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
      return false
    else {
      logout();
    }
  });

  //Textos da pagina
  const [btnTexto] = useState(history.location.state ? 'Salvar' : 'Cadastrar');
  const [titulo] = useState(history.location.state ? 'Editar' : 'Cadastrar');

  //Arrays dos inputs
  const [tussArray, setTussArray] = useState([]);
  const [clinicas, setClinicas] = useState([]);
  const [medicos, setMedicos] = useState([]);

  //Infos do form de cadastro
  const [medico, setMedico] = useState(tipo ? {
    value: JSON.parse(sessionStorage.getItem('data')).uid,
    email: JSON.parse(sessionStorage.getItem('data')).email
  } : '');
  const medicoRef = useRef(null);
  const [clinica, setClinica] = useState('');
  const clinicaRef = useRef(null);
  const [valoresTuss, setValoresTuss] = useState([{ title: '', code: undefined, valor: undefined, key: 0 }]);
  const [numeroDeCamposCriados, setNumeroDeCamposCriados] = useState(1);
  const [paciente, setPaciente] = useState('');
  const pacienteRef = useRef(null);
  const [plano, setPlano] = useState('');
  const planoRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const dateRef = useRef(null);
  const [file, setFile] = useState('');
  const fileRef = useRef(null);

  //Consts do Alerta
  const [message, setMessage] = useState('');
  const [type, setType] = useState("error");
  const [open, setOpen] = useState(false);

  const handleDateChange = (data) => {
    if (data) {
      if (typeof data.getMonth === 'function') {
        data.setHours(0, 0, 0, 0);
        setSelectedDate(data);
      }
    }
    else {
      setSelectedDate(null);
    }
  }

  //Logout do Usuário
  const logout = useCallback(() => {
    api
      .post('/logout')
      .then(() => {
        sessionStorage.clear();
        window.location.replace('/');
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let rota = tipo ? '/clinica/medico/' : '/assistente/clinicasMedicos/';

    //Request do array de TUSS cadastrados
    const getTuss = api.get('/procedimento/listTuss');
    const getClinicas = api.get(rota + JSON.parse(sessionStorage.getItem('data')).uid);

    Promise
      .all([getTuss, getClinicas])
      .then((res) => {

        //Preenche o array de TUSS
        let arr = [];
        res[0].data.forEach((tuss) => {
          arr.push({
            title: tuss.name,
            code: tuss.code
          });
        });
        setTussArray(arr);

        let temp = [];
        let tempMed = [];
        if (tipo) {
          if (res[1].data.length === 0) {
            setMessage('Você não possui clínicas cadastradas');
            setType('error');
            setOpen(true);
            return;
          }
          else
            res[1].data.forEach((clinica) => {
              temp.push({
                medic: JSON.parse(sessionStorage.getItem('data')).uid,
                value: clinica.uid,
                title: clinica.name,
              });
            });
        }
        else {
          if (res[1].data.length === 0) {
            setMessage('Você não está vinculado a nenhum médico');
            setType('error');
            setOpen(true);
            return;
          }
          else {
            res[1].data.forEach((medico) => {
              medico.clinica_list.forEach((clinica) => {
                temp.push({
                  medic: medico.medico_id,
                  value: clinica.uid,
                  title: clinica.name,
                });
              });

              tempMed.push({
                value: medico.medico_id,
                title: medico.medico_name,
                email: medico.medico_email,
              });
            });
          }
        }
        setClinicas(temp);
        setMedicos(tempMed);

        //Se for edição de um procedimento preenche as consts
        if (history.location.state) {
          let tuss = [];
          if (typeof history.location.state.valores === 'number') {
            tuss.push({
              title: history.location.state.tuss.name,
              code: history.location.state.tuss.code,
              valor: history.location.state.valores,
              key: 0
            });
            setNumeroDeCamposCriados(1);
          } else {
            for (let i = 0; i < history.location.state.tuss.length; i++) {
              tuss.push({
                title: history.location.state.tuss[i].name,
                code: history.location.state.tuss[i].code,
                valor: history.location.state.valores[i],
                key: i
              });
            };
            setNumeroDeCamposCriados(history.location.state.tuss.length);
          }
          setValoresTuss(tuss);
          setMedico({
            value: history.location.state.idMedico,
            email: history.location.state.emailMedico
          });
          setClinica(history.location.state.idClinica);
          setPaciente(history.location.state.pacienteCad);
          setPlano(history.location.state.planoSaude);
          handleDateChange(history.location.state.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data === "Unauthorized") {
              logout();
            }
            else if (err.response.data === "Não existem Clinicas com o registro desse médico") {
              setMessage('Você não possui clínicas cadastradas');
              setType('error');
              setOpen(true);
            }
            else
              alert(err.response.data);
          }
          else
            alert(err.response);
        }
        else
          alert(err);
      });
  }, [tipo, history.location.state, logout])

  //Muda o valor de um campo dentro do array dos TUSS
  const handleMudancaTuss = (index, json) => {
    let temp = [...valoresTuss];
    temp[index].title = json.title ? json.title : temp[index].title;
    temp[index].code = json.code ? json.code : temp[index].code;
    temp[index].valor = json.valor ? json.valor : temp[index].valor;
    setValoresTuss(temp);
  };

  //Remove um conjunto de TUSS e Valor
  const removerCampoTuss = (index) => {
    let arr = [...valoresTuss];
    arr.splice(index, 1);
    setValoresTuss(arr);
  };

  //Cria um conjunto de TUSS e Valor
  const adicionarCampoTuss = () => {
    setValoresTuss([...valoresTuss, { title: '', code: undefined, valor: undefined, key: numeroDeCamposCriados }]);
    setNumeroDeCamposCriados(numeroDeCamposCriados + 1);
  };

  //Limpa os campos após o cadastro de um procedimento
  const limpar = useCallback(() => {
    setClinica('');
    setPaciente('');
    setPlano('');
    setValoresTuss([{ title: '', code: undefined, valor: undefined, key: 0 }]);
    handleDateChange(null);
    setFile('');
  }, []);

  //Request de edição de procedimento
  const handleEditar = (form) => {
    form.preventDefault();
    setEnviando(true);
    let bodyFormData = new FormData();

    if (!selectedDate) {
      setMessage('Por Favor selecione uma data!');
      setType('error');
      setOpen(true);
      setEnviando(false);
      dateRef.current.focus();
      setTimeout(() => setOpen(false), 5000);
      return;
    }

    let tuss = [];
    let valores = [];
    let receber = [];
    valoresTuss.forEach((tussAtual) => {
      tuss.push({
        name: tussAtual.title,
        code: tussAtual.code,
      });
      valores.push(tussAtual.valor ? parseFloat(tussAtual.valor) : 0);
      receber.push(0);
    });

    let data = null;
    if (!(typeof selectedDate === 'string')) {
      data = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
    }

    bodyFormData.set('data', JSON.stringify({
      "email": medico.email,
      "medic_id": medico.value,
      "clinic_id": clinica,
      "patient_name": paciente,
      "tuss": tuss,
      "old_tuss": history.location.state.tuss,
      "health_plan": plano,
      "date": data ? data : selectedDate,
      "old_date": history.location.state.data,
      "registered_value": valores,
      "old_registered_value": history.location.state.valores,
      "received_value": receber,
    }));

    if (file)
      bodyFormData.append('file', file);
    api
      .patch("/procedimento/" + history.location.state.id, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(() => {
        setMessage("Procedimento Editado com Sucesso!");
        setType("success");
        setOpen(true);
        setEnviando(false);
        setTimeout(() => history.push('/atendimentos'), 2000);
      })
      .catch((err) => {
        setEnviando(false);
        if (err.response) {
          if (err.response.data) {
            if (err.response.data === "Unauthorized") {
              logout();
            }
            else if (err.response.data === 'Arquivo muito grande!') {
              setMessage('Arquivo Muito grande! O limite é de 25mb');
              setType('error');
              setOpen(true);
            }
            else
              alert(err.response.data);
          }
          else
            alert(err.response);
        }
        else
          alert(err);
      })
  };

  //Request de cadastro de um procedimento
  const handleCadastro = (form) => {
    form.preventDefault();
    setEnviando(true);
    let bodyFormData = new FormData();

    if (!selectedDate) {
      setMessage('Por Favor selecione uma data!');
      setType('error');
      setOpen(true);
      setEnviando(false);
      dateRef.current.focus();
      setTimeout(() => setOpen(false), 5000);
      return;
    }

    let tuss = [];
    let valores = [];
    let receber = [];
    valoresTuss.forEach((tussAtual) => {
      tuss.push({
        name: tussAtual.title,
        code: tussAtual.code,
      });
      valores.push(tussAtual.valor ? parseFloat(tussAtual.valor) : 0);
      receber.push(0);
    });

    bodyFormData.set('data', JSON.stringify({
      "email": medico.email,
      "medic_id": medico.value,
      "clinic_id": clinica,
      "patient_name": paciente,
      "tuss": tuss,
      "health_plan": plano,
      "date": format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
      "registered_value": valores,
      "received_value": receber
    }));

    if (file)
      bodyFormData.append('file', file);

    api
      .post("/procedimento", bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(() => {
        setMessage("Procedimento Cadastrado com Sucesso!");
        limpar();
        setType("success");
        setOpen(true);
        setEnviando(false);
        setTimeout(() => setOpen(false), 5000);
      })
      .catch((err) => {
        setEnviando(false);
        if (err.response) {
          if (err.response.data) {
            if (err.response.data === "Unauthorized") {
              logout();
            }
            else if (err.response.data === 'Arquivo muito grande!') {
              setMessage('Arquivo Muito grande! O limite é de 25mb');
              setType('error');
              setOpen(true);
            }
            else
              alert(err.response.data);
          }
          else
            alert(err.response);
        }
        else
          alert(err);
      })
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Collapse in={open} className="Alerta">
        <Alert
          variant="filled"
          severity={type}
          onClose={() => { setOpen(false) }}
        >
          {message}
        </Alert>
      </Collapse>

      <Navbar />

      <div className="CadastroProcedimento">
        <div className="cadProcedLeft">
          <div className="cadProcedTitulo">
            <p>{titulo}</p>
            <p>Atendimento</p>
          </div>

          <div className="CadastroProcedimentoDivider" />
        </div>

        <form className="cadProcedForm" onSubmit={history.location.state ? handleEditar : handleCadastro}>
          {!tipo &&
            <FormControl variant="outlined">
              <InputLabel id="medicolabel">Médico</InputLabel>
              <Select
                id="medico"
                required
                labelId="medicolabel"
                ref={medicoRef}
                value={medico}
                label="Médico"
                disabled={loading}
                onChange={(e) => setMedico(e.target.value)}
              >
                {!loading && medicos.length > 0 &&
                  medicos.map((medico, index) => {
                    return <MenuItem key={index} value={medico}>{medico.title}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          }
          <FormControl variant="outlined">
            <InputLabel id="clinicalabel">Clínica</InputLabel>
            <Select
              id="clinica"
              required
              labelId="clinicalabel"
              value={clinica}
              ref={clinicaRef}
              label="Clínica"
              disabled={((medico === '') ? true : false) || loading}
              onChange={(e) => setClinica(e.target.value)}
            >
              {!loading && medico !== '' &&
                clinicas.map((cnc, index) => {
                  if (cnc.medic === medico.value)
                    return <MenuItem key={index} value={cnc.value}>{cnc.title}</MenuItem>
                  return false;
                })
              }
            </Select>
          </FormControl>
          <div className="cadProcedFormDiv">
            <TextField
              id="nomepaciente"
              value={paciente}
              inputRef={pacienteRef}
              disabled={loading}
              onChange={(e) => setPaciente(e.target.value)}
              type="text"
              variant="outlined"
              label="Nome paciente"
              required
              autoComplete="name"
              className="cadProcedInput"
            />
            <TextField
              id="planoDeSaude"
              value={plano}
              inputRef={planoRef}
              disabled={loading}
              onChange={(e) => setPlano(e.target.value)}
              type="text"
              variant="outlined"
              label="Plano de saúde"
              required
            />
          </div>
          {valoresTuss.map((item, index) =>
            <div key={item.key} className="cadProcedFormDiv">
              <Autocomplete
                onChange={(event, value) => {
                  if (value) {
                    handleMudancaTuss(index, {
                      title: value.title,
                      code: value.code
                    })
                  }
                  else {
                    handleMudancaTuss(index, null);
                  }
                }}
                getOptionLabel={(option) => option.code + ' - ' + option.title}
                className="cadProcedInput cadProcedTuss"
                renderInput={(params) => (
                  <TextField {...params} required
                    label="TUSS" variant="outlined" />
                )}
                disabled={loading}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={tussArray}
              />
              <div className="divValorTuss">
                <TextField
                  name="valor"
                  type="int"
                  ref={item.ref}
                  value={item.valor ? item.valor : ''}
                  disabled={loading}
                  onChange={(e) => {
                    handleMudancaTuss(index, {
                      valor: e.target.value,
                    });
                  }}
                  variant="outlined"
                  label="Valor"
                  className="cadProcedValor"
                  InputProps={{ inputComponent: ValorCustom }}
                />
                {valoresTuss.length > 1 &&
                  <Tooltip title="Remover Procedimento">
                    <ClearIcon
                      onClick={() => removerCampoTuss(index)}
                      className="cadProcedRmvIcon"
                    />
                  </Tooltip>
                }
              </div>
            </div>
          )}

          <Tooltip title="Adicionar Procedimento">
            <AddIcon
              onClick={() => adicionarCampoTuss()}
              className="cadProcedAddIcon"
              fontSize='large'
            />
          </Tooltip>

          <div className="cadProcedFormDiv">
            <DatePicker
              inputVariant="outlined"
              ampm={false}
              inputRef={dateRef}
              disabled={loading}
              format="dd/MM/yyyy"
              animateYearScrolling={true}
              id="data"
              className="cadProcedData"
              value={selectedDate}
              onChange={handleDateChange}
              label="Data"
              required
            />
            <div className="cadProceddivArquivos">
              <label className="cadProcedFileLabel">Enviar Arquivo
                <input
                  className="cadProcedFile"
                  type='file'
                  ref={fileRef}
                  name='arquivo'
                  disabled={loading}
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </label>
            </div>
          </div>
          <button type="submit" className="cadProcedBtn" disabled={loading || enviando}>
            {!enviando && btnTexto}
            {enviando && <CircularProgress className="loadingCadProced" />}
          </button>
        </form>
      </div>
    </MuiPickersUtilsProvider >
  );
}

export default CadastroProcedimento;