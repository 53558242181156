export const MenuItens =[
    {
        title: 'Planos e preços',
        url: '/planos',
        cName: 'nav-links'
    },

    {
        title: 'Login',
        url: '/escolhe_usuario',
        cName: 'nav-links2'
    },
]