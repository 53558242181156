import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link, Redirect } from "react-router-dom";
import Logo from '../../assets/logopbranca.png';
import React, { useState } from 'react';
import './tipo_usuario.css';

//Pagina de escolha do tipo de usuário para o cadastro
function Tipo_user() {
  const [tipo] = useState(() => {
    if (sessionStorage.getItem('data'))
      if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'medico')
        return true
      else if (JSON.parse(sessionStorage.getItem('data')).userTipo === 'assistente')
        return false
  });

  if (!sessionStorage.getItem('data'))
    return (
      <div className="divTipoUsuario">
        <Link to='/'>
          <KeyboardBackspaceIcon className="voltarIcone" style={{ color: 'white' }} />
        </Link>
        <div className="logo">
          <img className="imglogo" src={Logo} alt="Logo" />
        </div>
        <div className="selecaoUser">
          <div className="tituloSelecao">
            <p>Olá, bem vindo!</p>
          </div>
          <div className="selecao">
            <Link to="/login?tipo=medico" className="cardSelecao">
              <div>
                <div>
                  <AccountCircleIcon className="usericonselecao" />
                </div>
                <button className="btnSelecao">Médico</button>
              </div>
            </Link>
            <Link to="/login?tipo=assistente" className="cardSelecao">
              <div>
                <div>
                  <AccountCircleIcon className="usericonselecao" />
                </div>
                <button className="btnSelecao">Assistente</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
    
  if (tipo)
    return <Redirect to='/grafico' />

  else if (!tipo)
    return <Redirect to='/atendimentos' />
}

export default Tipo_user;
